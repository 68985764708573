import { useEffect, useState } from 'react';
import { StyleSheet, View, Text, Pressable} from 'react-native';
import Titre from '../Titre';
import InputComponent from '../InputComponent';
import Button from '../Button';
import utilisateur_service from '../../services/utilisateur.service';
import attribution_service from '../../services/attribution.service';
import { css_black, css_darkBlue, css_green, css_grey, css_lightBlue, css_red, css_white } from '../../constants/Colors';
import workflow_service from '../../services/workflow.service';
import SelecteurDroits from '../Elements/SelecteurDroits';
import { screenWidth } from '../../constants/Const';

export default function AjoutAttributions({idLogement, goBack = null, alreadyAttributed = [], informations = []}) {
    const [loading, setLoading] = useState(true);
    const [selectedRole, setSelectedRole] = useState("");
    const [selectedUser, setSelectedUser] = useState({});
    const [choseAlreadyAttributed, setChoseAlreadyAttribution] = useState(-1)
    const [message, setMessage] = useState("");
    const [displayMessage, setDisplayMessage] = useState(false);
    const [workflows, setWorkflows] = useState([])
    const [filteredWorkflows, setFilteredWorkflow] = useState([])
    
    useEffect(() => {
        setLoading(true);
            workflow_service.getWorkflows()
            .then((w) => {
                let tmp = w;
                tmp.forEach((t) => {
                    t.droit = false
                })
                tmp.push({
                    libelle: "Nestors",
                    droit: false
                });
                tmp.push({
                    libelle: "Propriétaires",
                    droit: false
                })
                setWorkflows(tmp)
                setFilteredWorkflow(tmp)
                setLoading(false)
            })
        
    }, [])

    const attribuerRole = () => {
        if (choseAlreadyAttributed === -1) {
            utilisateur_service.getIdByMail(selectedUser).then((res) => {
                if (res.result.id_utilisateur === -1) {
                    setMessage(res.result.message);
                    launchMessage()
                } else {
                    launchAttribution(res.result.id_utilisateur)
                }
            })
        } else {
            launchAttribution(choseAlreadyAttributed)
        }   
        
    }

    const launchAttribution = (id_utilisateur) => {    
        setLoading(true);
        const promises = [];
        if (filteredWorkflows.filter(w => w.libelle === "Propriétaires")[0].droit) {
            promises.push(attribution_service.createProprietaire(id_utilisateur, idLogement));
        } else {
            promises.push(attribution_service.deleteProprietaire(id_utilisateur, idLogement));
        }
        if (filteredWorkflows.filter(w => w.libelle === "Nestors")[0].droit) {
            promises.push(attribution_service.createNestor(id_utilisateur, idLogement));
        } else {
            promises.push(attribution_service.deleteNestor(id_utilisateur, idLogement));
        }

        promises.push(attribution_service.createAttribution(id_utilisateur, filteredWorkflows.filter(w => w.libelle !== "Propriétaires" && w.libelle !== "Nestors"), idLogement));

        Promise.all(promises)
        .then((r) => {
            setMessage("L'attribution a été créée.")
            launchMessage();
            setLoading(false);
        });
    }

    const launchMessage = () => {
        setDisplayMessage(true)
        setTimeout(() => {
            setDisplayMessage(false)
            if (goBack !== null) goBack()
        }, 1000)
    }
    const update = ({id_utilisateur, id_workflow, id_logement, libelle = ""}) => {
        let index;
        if (libelle === "") {
            index = filteredWorkflows.findIndex((w) => w.id_workflow === id_workflow);
        } else {
            index = filteredWorkflows.findIndex((w) => w.libelle === libelle);
        }
        setFilteredWorkflow([
            ...filteredWorkflows.slice(0, index),
            {...filteredWorkflows[index], droit: !filteredWorkflows[index].droit},
            ...filteredWorkflows.slice(index + 1)
        ])
        
    }

    const filterAttribution = () => {
        const tmp = []
        for (let i = 0; i < workflows.length; i++) {
            tmp.push(workflows[i])
            tmp[i].droit = false;
        }
        if (choseAlreadyAttributed !== -1) {
            for (let i = 0; i < workflows.length; i++) {
                informations.forEach((p) => {
                    if (workflows[i].libelle === "Nestors") {
                        tmp[i].droit = informations.filter(r => r.libelle === "Nestors")[0].personnel.filter(p => p.id_utilisateur === choseAlreadyAttributed).length !== 0;    
                    }
                    if (workflows[i].libelle === "Propriétaires") {
                        tmp[i].droit = informations.filter(r => r.libelle === "Propriétaires")[0].personnel.filter(p => p.id_utilisateur === choseAlreadyAttributed).length !== 0;
                    }
                    if (p.id_workflow === workflows[i].id_workflow) {
                        p.personnel.forEach((r) => {
                            if (r.id_utilisateur === choseAlreadyAttributed)
                                tmp[i].droit = true
                        })
                    }
                    
                })
            }
        }   
        setFilteredWorkflow(tmp)
        
    }

    useEffect(() => {
        filterAttribution()
    }, [choseAlreadyAttributed])

    const styles = StyleSheet.create({
        listRole: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center"
        },
        button: {
            width: Math.min(screenWidth*0.9, 500*0.9),
            marginTop: "2vh"
        },
        role: {
            borderColor: css_grey,
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "15px",
            padding: "2.5%",
            marginRight: "1vw",
            marginLeft: "1vw",
            marginTop: "1vh",
            marginBottom: "1vh"
        },
        container: {
            width: Math.min(screenWidth*0.9, 500*0.9),
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "5vh"
        },
        selectedCard: {
            display: "flex",
            flexDirection: "row",
            borderColor: css_darkBlue,
            backgroundColor: css_darkBlue,
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "15px",
            marginHorizontal: "1%",
            marginVertical: "1%",
            paddingHorizontal: "2%",
            paddingVertical: "2%",
            justifyContent: 'center',
        },
        card: {
            display: "flex",
            flexDirection: "row",
            borderColor: css_darkBlue,
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "15px",
            marginHorizontal: "1%",
            marginVertical: "1%",
            paddingHorizontal: "2%",
            paddingVertical: "2%",
            justifyContent: 'center',
            
        },
        text: {
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column"
        },
        droitAttribution: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width:"80%",
            alignItems: "center",
            marginVertical: "1vh"
        },
        containerRoles: {
            backgroundColor: css_lightBlue,
            width: "80%",
            borderRadius: "15px",
            borderColor: css_darkBlue,
            borderWidth: "1px",
            marginVertical: "2vh"
        },
        list: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-around"
        }
    });
    

    return (!loading ?
       <View style={styles.container}>
        {goBack !== null ? <><Button    title="Annuler"
                                        color={css_red}
                                        background={css_white} 
                                        onPress={() => {goBack()}}/>
        <Titre  texte="Attribuer un workflow" 
                size={1.1}
                color={css_black}
                marginBottom={2}/></>
        : <></>}

        <View >
            <InputComponent libelle="Nom d'utilisateur" 
                            color={css_grey}
                            onChange={setSelectedUser}/>
        </View>

        <View style={styles.list}>
            {alreadyAttributed.map((a,key) => {
                return (<Button title={a.utilisateur} 
                                marginBottom={1}
                                key={`AjoutAttributionsAlready${key}`}
                                background={a.id_utilisateur === choseAlreadyAttributed ? css_green : css_lightBlue}
                                color={a.id_utilisateur === choseAlreadyAttributed ? css_white : css_darkBlue}
                                onPress={() => {if (a.id_utilisateur === choseAlreadyAttributed)
                                                    setChoseAlreadyAttribution(-1);
                                                else 
                                                    setChoseAlreadyAttribution(a.id_utilisateur)}}
                                />)
            })}
        </View>

        {selectedRole !== 1 && selectedRole !== 2 ?<>
            <Titre  texte="Modifier les droits sur chaque workflow" 
                    size={1.1}
                    color={css_black}
                    marginBottom={2}/>
            {filteredWorkflows.map((w, key) => {
                return (w.libelle !== "Nestors" && w.libelle !== "Propriétaires" ?
                    <View style={styles.droitAttribution} key={`AjoutAttributions${key}`}>
                        <Text   style={{color: css_grey}}>{w.libelle}</Text>
                        <SelecteurDroits    id_workflow={w.id_workflow}
                                            etat={w.droit}
                                            onUpdate={update} />    
                    </View> : <View key={`AjoutAttributions${key}`}></View>
                )
            })}</>

        : <></>}

        <Titre  texte="Choisir un rôle d'administration" 
                    size={1.1}
                    color={css_black}
                    marginBottom={2}/>
            <View style={styles.droitAttribution} key={`AjoutAttributionsNestor`}>
                <Text   style={{color: css_grey}}>Nestor</Text>
                <SelecteurDroits    etat={filteredWorkflows.filter(w => w.libelle === "Nestors")[0].droit}
                                    onUpdate={() => {update({libelle: "Nestors"})}} />    
            </View>
            <View style={styles.droitAttribution} key={`AjoutAttributionsProprietaire`}>
                <Text   style={{color: css_grey}}>Propriétaire</Text>
                <SelecteurDroits    etat={filteredWorkflows.filter(w => w.libelle === "Propriétaires")[0].droit}
                                    onUpdate={() => {update({libelle: "Propriétaires"})}} />    
        </View>            
        
        
        {displayMessage ? <Text>{message}</Text> : <></> }

        <View style={styles.button}>
            <Button title="Attribuer les workflows" 
                    background={css_green} 
                    color={css_white}
                    fontWeight="400"
                    onPress={attribuerRole}/>
        </View>
        
       </View> 
       : <></>
    );
}

