import { StyleSheet, Text, View, Image } from 'react-native';
import { css_darkBlue, css_white } from '../constants/Colors';
import {nestorLogo, bottomHome, fullLoading} from '../constants/Image';
import { screenWidth } from '../constants/Const';

export default function Loading() {

  return (
    <View style={styles.container}>
      <View style={styles.top}></View>
      <Image  source={nestorLogo} 
              style={styles.logoNestor} />
      <Text style={styles.titre}>Chargement en cours...</Text>
      <Image  source={bottomHome} 
              style={styles.bottomHome} />
    </View>
  );
}

const styles = StyleSheet.create({
  top: {
    flex:2
  },
  container: {
    flex: 1,
    backgroundColor: css_darkBlue,
    alignItems: 'center',
    justifyContent: 'center'
  },
  titre: {
    color: css_white,
    fontWeight: '200',
    marginTop: '5vh',
    marginBottom: '5vh',
    fontSize: "2rem",
    letterSpacing: Math.min(screenWidth*0.01,500*0.01)
  },
  logoNestor: {
    width: Math.min(screenWidth*0.2,500*0.2),
    height: Math.min(screenWidth*0.23,500*0.23)
  },
  bottomHome: {
    marginTop: Math.min(screenWidth*0.3,500*0.3),
    width: Math.min(screenWidth,500),
    height: Math.min(screenWidth*0.22,500*0.22)
  },
  loadingView: {
    backgroundColor: css_white,
    flex: 2,
    width: "100%",
    alignItems: 'center',
    justifyContent: 'center'
  },
  fullLoading: {
    width:Math.min(screenWidth*0.5,500*0.5),
    height:Math.min(screenWidth*0.1,500*0.01),
    
  }
});
