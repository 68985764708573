import { useState } from 'react';
import { StyleSheet, Text, View, Image } from 'react-native';
import { nestorLogo, bottomHome, fullLoading } from '../constants/Image';
import Connexion from '../components/Connexion';
import Inscription from '../components/Inscription';
import Button from '../components/Button';
import { css_darkBlue, css_lightBlue, css_white } from '../constants/Colors';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import auth_service from '../services/auth.service';
import { screenWidth } from '../constants/Const';

export default function Authentification() {
    const [displayConnexion, setDisplayConnexion] = useState(false);
    const [displayInscription, setDisplayInscription] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
      if(auth_service.isConnected()) navigate("/accueil")
    }, [])


    const changeConnexion = () => {
      setDisplayInscription(false);
      setDisplayConnexion(!displayConnexion);
    }

    const changeInscription = () => {
      setDisplayConnexion(false);
      setDisplayInscription(!displayInscription);
    }

    if (displayConnexion) {
      return (
        <Connexion onBack={changeConnexion} />
      )
    } else if (displayInscription) {
      return (
        <Inscription onBack={changeInscription} launchConnexion={changeConnexion} />
      )
    } else {
      return (
        <View       style={styles.container}>
            <View   style={styles.top}></View>
            <Image  source={nestorLogo} 
                    style={styles.logoNestor} />
            {/*<Text   style={styles.titre}>MERCI NESTOR</Text>*/}
            <View   style={styles.buttons} >
                <Button onPress={changeConnexion}
                        title="Connexion"
                        color={css_white}
                        background={css_lightBlue}
                        fontWeight="700"
                        accessibilityLabel="Bouton de redirection vers l'écran de connexion."
                />
                <Button onPress={changeInscription}
                        title="S'inscrire"
                        color={css_white}
                        background=""
                        fontWeight="300"
                        accessibilityLabel="Bouton de redirection vers l'écran d'inscription'."
                />
            </View>
            <Image  source={bottomHome} 
                    style={styles.bottomHome} />
            <View style={styles.loadingView}>
            <View style={styles.loadingAnimation} >
                <Image  source={fullLoading}
                      style={styles.fullLoading}
                      />
            </View>
          </View>
        </View>
      );
    }
    
}
const styles = StyleSheet.create({
top: {
  flex:2
},
container: {
  flex: 1,
  backgroundColor: css_darkBlue,
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: Math.min(screenWidth, 500),
  maxWidth: Math.min(screenWidth, 500)
},
titre: {
  color: css_white,
  fontWeight: '200',
  marginTop: '5vh',
  marginBottom: '5vh',
  fontSize: "2rem",
  letterSpacing: '1%'
},
logoNestor: {
  width: "200px",
  height: "230px"
},
bottomHome: {
  width: "100%",
  height: "22%"
},
loadingView: {
  backgroundColor: css_white,
  flex: 2,
  width: "100%",
  alignItems: 'center',
  justifyContent: 'center'
},
fullLoading: {
  width:"50%",
  height:"10%",
  
},
buttons: {
  width: "80%",
  marginTop: "1vh",
  marginBottom: "1vh"
}
});
    