import { StyleSheet, Pressable, Text, View } from 'react-native';
import { css_grey } from '../../constants/Colors';
import { AntDesign } from '@expo/vector-icons';
import { screenWidth } from '../../constants/Const';

export default function AdminLink({titre = "Non fourni", isPressable = false, 
    actionOne = () => {},
    actionOneIcon = <AntDesign  name="arrowright"   size={24} color={css_grey}/>,
    actionTwo = () => {},
    actionTwoIcon = <></>,
    actionThree = () => {},
    actionThreeIcon = <></>
     }) {

  if (isPressable) {
    return (
        <Pressable  style={styles.ctn}
                    onPress={actionOne}>
            <Text   style={styles.titre}>{titre}</Text>
            <View   style={styles.actions}>
                {actionOneIcon}
            </View>
        </Pressable>
    );
    
  } else {
    return ( 
        <View style={styles.ctn}>
            <Text   style={styles.titre}>{titre}</Text>
            <View   style={styles.actions}>
                <Pressable  onPress={actionOne}>{actionOneIcon}</Pressable>
                <Pressable  onPress={actionTwo}>{actionTwoIcon}</Pressable>
                <Pressable  onPress={actionThree}>{actionThreeIcon}</Pressable>
            </View>
        </View>
    );
  }
  
}


const styles = StyleSheet.create({ 
    ctn: {
        width: "90%",
        borderColor: css_grey,
        borderWidth: "1px",
        borderRadius: "15px",
        marginVertical: Math.min(screenWidth*0.01, 0.01*500),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    titre: {
        color: css_grey,
        fontWeight: "600",
        marginVertical:"1vh",
        marginLeft: Math.min(screenWidth*0.03, 0.03*500),
        width: Math.min(screenWidth/2,500/2),
    },
    actions: {
        marginRight: Math.min(screenWidth*0.03,500*0.03),
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "space-between",
        width: Math.min(screenWidth*0.35,500*0.35),

    }
});