import { useEffect, useState } from 'react';
import { StyleSheet, Pressable, Text } from 'react-native';
import { css_bg_green, css_black, css_white } from '../constants/Colors';
import { screenWidth } from '../constants/Const';
import { View } from 'react-native-web';


export default function Button({disabled = false, onPress, title, background, color, fontWeight, size, borderColor = null, marginBottom = undefined, customStyle = []}) {
    const [t, setTitle] = useState("");
    const [b, setBackground] = useState(css_white);
    const [c, setColor] = useState(css_black);
    const [fw, setFontWeight] = useState("500");

    useEffect(() => {
        setTitle(title);
    }, [title])

    useEffect(() => {
        setBackground(background);
    }, [background])

    useEffect(() => {
        setColor(color);
    }, [color])

    useEffect(() => {
        setFontWeight(fontWeight);
    }, [fontWeight])

    const press = () => {
        onPress();
    }

    const styles = StyleSheet.create({
        button: {
            backgroundColor: b,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: "15px",
            /*width: "100%",*/
            padding: Math.min(screenWidth*0.02, 500*0.02),
            textAlign: "center",
            paddingTop: "1vh",
            paddingBottom: "1vh",
            paddingLeft: `${size === "medium" ? Math.min(screenWidth*0.05, 500*0.05) : ""}`,
            paddingRight: `${size === "medium" ? Math.min(screenWidth*0.05, 500*0.05) : ""}`,
            display: "flex",
            flexDirection: "row",
            borderColor: borderColor != null ? borderColor : "",
            borderWidth: borderColor != null ? "1px" : "0px",
            marginBottom: marginBottom === undefined ? "0vh" : marginBottom
        },
        text: {
            color: c, 
            width: "100%",
            height: "100%",
            fontWeight: fw
        }
    });
    
    if (disabled) return (<View style={[styles.button, {backgroundColor: css_bg_green}].concat(customStyle)}><Text   style={styles.text}>{t}</Text></View>)
    return (
        <Pressable  style={[styles.button].concat(customStyle)} 
                    onPress={press}>
            <Text   style={styles.text}>{t}</Text>
        </Pressable>
    );
}

