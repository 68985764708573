import { StyleSheet, View, Image, ImageBackground, Pressable } from 'react-native';
import { decoNavbar2, decoNavbar3 } from '../constants/Image';
import { useEffect, useState } from 'react';
import { css_black, css_darkBlue, css_grey, css_orange, css_white } from '../constants/Colors';
import { Ionicons } from '@expo/vector-icons';
import { screenWidth } from '../constants/Const';

export default function Header({onBack, selection, image = ''}) {
    const [selected, setSelected] = useState(1);

    useEffect(() => {
        setSelected(selection)
    },[selection])


    const retour = () => {
        onBack()
    }

    const styles = StyleSheet.create({
        backButton: {
            position: "absolute",
            top: "40px",
            left: "10px",
            backgroundColor: css_white,
            borderRadius: "100%",
            width: Math.min(50, screenWidth*0.11),
            height: Math.min(50, screenWidth*0.11),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center"
        },
        icon: {
            fontSize: `1.1rem`,
            color: css_grey
        },
        container: {
            backgroundColor: selected === 2 ? css_orange : css_darkBlue,
            alignItems: 'center',
            justifyContent: 'center',
            height: "30vh",
        },
        filtrePhoto: {
            backgroundColor: css_black,
            opacity: 0.2,
            position: "absolute",
            width: "100%",
            height: "30vh",

        }, 
        decoView: {
            width: "100%",
            flex: 1,
            display: "flex",
            alignContent:"flex-end",
            justifyContent: "flex-end"
        },
        deco: {
            width: "137px",
            height: "54px",
            alignSelf: "flex-end",
            marginRight: "1vw",
            marginTop: "1vh"
        },
        deco2: {
            width: "15vw",
            height: "12vw",
            alignSelf: "flex-end",
            marginRight: "1vw",
            marginTop: "5vh"
        }
    });

    if (selected === 2 && image !== '') {
        return (
                <ImageBackground source={image} 
                                resizeMode="cover" 
                                style={styles.container}>
                    <View style={styles.filtrePhoto} />
                    <View style={styles.decoView}>
                        <Pressable  onPress={retour} 
                                    style={styles.backButton}>
                            <View >
                                <Ionicons name="chevron-back" style={styles.icon}/>
                            </View>    
                        </Pressable>
                    </View>
                </ImageBackground>
        );
    } 
    else if (selected === 2 && (image === '' || image === "defaut.png")) {
        return (
            <View style={styles.container}>
                <View style={styles.decoView}>
                    <Pressable  onPress={retour} 
                                    style={styles.backButton}>
                            <View >
                                <Ionicons name="chevron-back" style={styles.icon}/>
                            </View>    
                        </Pressable>
                    
                    <Image  source={decoNavbar3} 
                            style={styles.deco2} />
                </View>
            </View>

        )
    }else {
        return (
            <View style={styles.container}>
                <View style={styles.decoView}>
                    <Pressable  onPress={retour} 
                                    style={styles.backButton}>
                            <View >
                                <Ionicons name="chevron-back" style={styles.icon}/>
                            </View>      
                        </Pressable>
                    
                    <Image  source={decoNavbar2} 
                            style={styles.deco} />
                </View>
            </View>
        );
    }
    
}


