import { StyleSheet, View, Text } from 'react-native';
import Header from '../components/Header';
import Button from './Button';
import { useNavigate } from 'react-router-dom';
import InputComponent from './InputComponent';
import { useState } from 'react';
import auth_service from '../services/auth.service';
import { css_lightBlue, css_red, css_white } from '../constants/Colors';
import { screenWidth } from '../constants/Const';


export default function Connexion({onBack}) {
    const [toSend, setToSend] = useState({
        utilisateur: "",
        mot_de_passe: ""
    })
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [displayMessage, setDisplayMessage] = useState(false);
    const [formIsValid, setFormIsValid] = useState(false);

    const navigate = useNavigate();

    const retour = () => {
        onBack();
    }

    const goToAccueil = () => {
        setLoading(true);
        auth_service.tryConnexion(toSend).then((res) => {
            setLoading(false);
            if (res.result) navigate("/accueil");
            else {
                setMessage(res.message);
                launchMessage()
            }
        })
        
    }

    const launchMessage = () => {
        setDisplayMessage(true);
        setTimeout(() => {
            setDisplayMessage(false)
        }, 5000)
    }

    const utilisateurChanged = (newUtilisateur) => {
        const tmp = toSend;
        toSend.utilisateur = newUtilisateur.toLowerCase();
        setToSend(tmp)
        utilisateurEstValide(newUtilisateur.toLowerCase());
    }

    const utilisateurEstValide = (newUtilisateur) => {
        //const regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/i);
        const regex = new RegExp(/^[A-Za-z0-9@.]{5,}$/i);
        setFormIsValid(regex.test(newUtilisateur))
    }

    const mdpChanged = (newMdp) => {
        const tmp = toSend;
        toSend.mot_de_passe = newMdp;
        setToSend(tmp)
    }

    return (
        <View style={styles.container}>
            <Header onBack={retour} />
            <View style={styles.checkContainer}>
                <Text style={styles.messagedone}>Connexion</Text>
            </View>
            <View style={styles.form} >
                <View style={styles.taille}>
                    <InputComponent libelle="Nom d'utilisateur" 
                                    onChange={utilisateurChanged}
                                    disable={loading}
                                    />
                    <InputComponent libelle="Mot de passe" 
                                    onChange={mdpChanged}
                                    inputType="password" 
                                    disable={loading}
                                    />
                    {displayMessage ? <Text style={styles.message}>{message}</Text> : <></>}
                    { formIsValid ?
                        <Button onPress={goToAccueil}
                            title={loading ? "Connexion en cours ...": "Continuer"}
                            color={css_white}
                            background={css_lightBlue}
                            fontWeight="700"
                            accessibilityLabel="Bouton de validation de la connexion."
                        /> : <></>}

                </View>
                
            </View>
        </View>
    );
}
    
const styles = StyleSheet.create({
    container: {
        minWidth: Math.min(screenWidth, 500),
        maxWidth: Math.min(screenWidth, 500),
        height: "100vh",
        backgroundColor: css_white
    },
    form: {
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        width: "100%"
    },
    taille: {
        width: "90%"
    },
    message: {
        fontSize: "1.2rem",
        color: css_red
    },
    checkContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      marginVertical: "5vh"
    },
    messagedone: {
        fontSize: "1.4rem",
    },
});
