import { StyleSheet, View, Text} from "react-native";
import { css_black, css_darkBlue, css_green, css_grey, css_jaune, css_orange, css_red, css_white } from "../../constants/Colors";

/**
 * Composnant Chip permettant de filtrer les workflows.
 * @param {*} param0 
 * @returns 
 */
export default function TacheChip({color = "", border = true, selected = false, libelle = "", shadowed = false}) {
    const styles = StyleSheet.create({
        chip: {
        },
        text: {
            marginVertical: "1vh",
            marginRight: border ? "1vh" : "0",
            paddingVertical: "0.5vh",
            paddingHorizontal: "3vw",
            borderColor:    color === "red" && !selected ? css_red
                            : color === "blue" && !selected ? css_darkBlue
                            : color === "orange" && !selected ? css_orange
                            : color === "yellow" && !selected ? css_jaune
                            : color === "grey" && !selected ? css_grey
                            : (color === "green" || color === "deleted") && !selected ? css_green
                            : css_white,
            backgroundColor: color === "red" && selected ? css_red
                            : color === "blue" && selected ? css_darkBlue
                            : color === "orange" && selected ? css_orange
                            : color === "yellow" && selected ? css_jaune
                            : color === "grey" && selected ? css_grey
                            : (color === "green" || color === "deleted") && selected ? css_green
                            : css_white,
            color:          selected ? css_white
                            : color === "blue" ? css_darkBlue
                            : color === "red" ? css_red
                            : color === "orange" ? css_orange
                            : color === "yellow" ? css_jaune
                            : color === "grey" ? css_grey
                            : (color === "green" || color === "deleted") ? css_green
                            : css_black,
            borderWidth: border ? "2px" : "0px",
            borderRadius: "30px",
            fontSize: "0.8rem",
            opacity: shadowed ? 0.5 : 1
        }
    });

    return (
        <View style={styles.chip} >  
            <Text style={styles.text} >
                {libelle}
            </Text>
        </View>
    );

}




