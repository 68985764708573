import { StyleSheet, View } from "react-native";
import { css_green, css_red } from "../../constants/Colors";
import Button from "../Button";

/**
 * Composant permettant la sélection des droits sur la page de Logement.
 * @param {*} param0 
 * @returns 
 */
export default function SelecteurDroits({id_workflow = undefined, id_utilisateur = -1, onUpdate = null, id_logement = -1, etat=true, libelle =""}) {

    if (libelle === "Nestors" || libelle === "Propriétaires") {
        return (
            <View style={styles.droitsButton}>
                <Button title={etat ? "Désattribuer" : "Attribuer"} 
                        background={undefined}
                        color={etat ? css_red : css_green}
                        onPress={() => {onUpdate({id_utilisateur: id_utilisateur, libelle:libelle, id_logement: id_logement})}}/>
                
            </View>
        );
    } else {
        return (
            <View style={styles.droitsButton}>
                <Button title={etat ? "Désattribuer" : "Attribuer"} 
                        background={undefined}
                        color={etat ? css_red : css_green}
                        onPress={() => {onUpdate({id_utilisateur: id_utilisateur, id_workflow:id_workflow, id_logement: id_logement})}}/>
                
            </View>
        );
    }
    

}
const styles = StyleSheet.create({
    droitsButton: {
        display: "flex",
        flexDirection: "row"
    },
});



