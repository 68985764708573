import { StyleSheet, Pressable, View, Text } from 'react-native';
import { css_darkBlue, css_white } from '../constants/Colors';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { MaterialIcons  } from '@expo/vector-icons';
import moment from 'moment-timezone';
import Titre from '../components/Titre';
import programmation_service from '../services/programmation.service';
import TachesActuelles from '../components/Logement/TachesActuelles';
import UsersRecapModale from '../components/Modales/UsersRecapModale';
import HomesRecapModale from '../components/Modales/HomesRecapModale';
import UsersHomesFilter from '../components/Filters/UsersHomesFilter';
import DatesFilter from '../components/Filters/DatesFilter';
import RecapTable from '../components/Elements/RecapTable';
import RecapView from '../components/Elements/RecapView';
import ClearSeparation from '../components/Elements/ClearSeparation';
import { screenWidth } from '../constants/Const';

export default function Recapitulatif({seen_by = undefined, setDisplayRecap = () => true}) {
    const [selectedBegin, setSelectedBegin] = useState(moment("2024-01-01").tz("Europe/Paris"));
    const [selectedEnd, setSelectedEnd] = useState(moment("2024-01-31").tz("Europe/Paris"));
    const [selectedMonth, setSelectedMonth] = useState("PÉRIODE");
    const [recapitulatifListe, setRecapitulatifListe] = useState([]);
    const [recapitulatifTableau, setRecapitulatifTableau] = useState([]);

    const [selectedWorkflows, setSelectedWorkflow] = useState(-1);

    const [enableLogement, setEnableLogement] = useState(true);
    const [enableUser, setEnableUser] = useState(true);
    const [displayPart, setDisplayPart] = useState("recap");

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedHomes, setSelectedHomes] = useState([]);

    const [displayUsersModale, setDisplayUsersModale] = useState(false);
    const [listOfAllUsers, setListOfAllUsers] = useState([]);
    const [displayHomesModale, setDisplayHomesModale] = useState(false);
    const [listOfAllHomes, setListOfAllHomes] = useState([]);
    const [listOfAllWorkflows, setListOfAllWorkflows] = useState([]);
    
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchPreviousMonth();
    }, []);

    useEffect(()=> {
        fetch("firstLoad");
    }, [selectedBegin, selectedEnd]);

    useEffect(() => {
        fetch("home");
    }, [selectedHomes]);

    useEffect(() => {
        fetch("user");
    }, [selectedUsers]); 

    const fetch = (filtre = "firstLoad") => {
        setLoading(true);
        programmation_service.getRecapitulatif(
            selectedBegin, 
            selectedEnd,
            filtre === "firstLoad"  || selectedUsers.length === listOfAllUsers.length? undefined : selectedUsers,
            filtre === "firstLoad" || selectedHomes.length === listOfAllHomes.length? undefined : selectedHomes,
            seen_by)
        .then((r) => {
            // FirstLoad
            if (filtre === "firstLoad") {
                setRecapitulatifTableau(r.tableau);
                setRecapitulatifListe(r.liste.workflows);
                setListOfAllWorkflows(r.tableau);
                cleanWorkflowSelection(r.liste.workflows);
                parseHomes(r.tableau);
                parseUsers(r.tableau);
            }
            // On home filter update
            if (filtre === "home") {
                setRecapitulatifTableau(r.tableau);
                setRecapitulatifListe(r.liste.workflows);
            }

            // On user filter update 
            if (filtre === "user") {
                setRecapitulatifTableau(r.tableau);
                setRecapitulatifListe(r.liste.workflows);
            }
            setLoading(false);
        })
        .catch((err) => {
        });
    }

    const cleanWorkflowSelection = (workflows) => {
        if (workflows.filter(w => w.id_workflow === selectedWorkflows).length === 0) setSelectedWorkflow(-1);
    }

    const cleanFilter = (tableau) => {
        let newWorklowSelection = -1;
        const newUsersSelection = [];
        const newHomesSelection = [];
        for (let i = 0; i < tableau.length; i++) {
            if (selectedHomes.includes(tableau[i].id_logement) && !newHomesSelection.includes(tableau[i].id_logement))
                newHomesSelection.push(tableau[i].id_logement);
            if (selectedUsers.includes(tableau[i].modified_by) && !newUsersSelection.includes(tableau[i].modified_by))
                newUsersSelection.push(tableau[i].modified_by);
            if (selectedWorkflows === tableau[i].id_workflow)
                newWorklowSelection = tableau[i].id_workflow;
        } 
        setSelectedWorkflow(newWorklowSelection);
        setSelectedHomes(newHomesSelection);
        setSelectedUsers(newUsersSelection);
    }


    const parseUsers = (tableau) => {
        setListOfAllUsers([]);
        setSelectedUsers([]);
        const tmp = [];
        for (let i = 0; i < tableau.length; i++) {
            if (tmp.filter((u) => u.id_utilisateur === tableau[i].modified_by).length === 0) {
                tmp.push({id_utilisateur: tableau[i].modified_by, utilisateur: tableau[i].utilisateur})
                setListOfAllUsers(prev => [...prev, {id_utilisateur: tableau[i].modified_by, utilisateur: tableau[i].utilisateur}]);
                setSelectedUsers(prev => [...prev, tableau[i].modified_by]);
            }
        } 
    };

    const parseHomes = (tableau) => {
        setListOfAllHomes([])
        setSelectedHomes([]);
        const tmp = [];
        for (let i = 0; i < tableau.length; i++) {
            if (tmp.filter((u) => u.id_logement === tableau[i].id_logement).length === 0) {
                tmp.push({id_logement: tableau[i].id_logement, libelle_logement: tableau[i].libelle_logement})
                setListOfAllHomes(prev => [...prev, {id_logement: tableau[i].id_logement, libelle_logement: tableau[i].libelle_logement}]);
                setSelectedHomes(prev => [...prev, tableau[i].id_logement]);
            }
        } 
    };


    const fetchPreviousMonth = () => {
        setSelectedMonth(`${moment.tz("Europe/Paris").subtract(1, "month").format("MMMM YYYY").toUpperCase()}`);
        setSelectedBegin(moment.tz("Europe/Paris").subtract(1, "month").startOf("month"));
        setSelectedEnd(moment.tz("Europe/Paris").subtract(1, "month").endOf("month"));
    }

    return (
        <View style={styles.container}>
            {/* Navbar */}
            <View style={styles.navbar}>
                {seen_by !== undefined ? 
                <Pressable  style={styles.navbarLink}
                            onPress={(e) => {e.preventDefault();setDisplayRecap(false)}}>
                    <MaterialIcons name="arrow-back" size={24} color={css_white} />
                </Pressable>
                :
                <Pressable  style={styles.navbarLink}
                            onPress={(e) => {e.preventDefault();navigate('/accueil')}}>
                    <MaterialIcons name="arrow-back" size={24} color={css_white} />
                </Pressable>}
            </View>

            {/* Dates Filter */}
            <DatesFilter    selectedBegin={selectedBegin}
                            setSelectedBegin={setSelectedBegin} 
                            selectedEnd={selectedEnd} 
                            setSelectedEnd={setSelectedEnd} 
                            selectedMonth={selectedMonth} 
                            setSelectedMonth={setSelectedMonth}/>

            <ClearSeparation />
        {recapitulatifListe.length === 0 ? 
        <View style={{display:"flex", width:"100%", justifyContent: "center"}}>
            <Text style={{fontSize: "1.2rem"}}>Aucune réservation trouvée avec ces filtres...</Text>
        </View>:
        <>    
            {/* Workflows Filter */}
            <TachesActuelles    taches={listOfAllWorkflows}
                                page="recap"
                                filter={selectedWorkflows}
                                onSelection={setSelectedWorkflow}/>

            <ClearSeparation />

            {/* Homes & Users Filter */}
            <UsersHomesFilter   enableLogement={enableLogement}
                                setEnableLogement={setEnableLogement}
                                enableUser={enableUser}
                                setEnableUser={setEnableUser}
                                selectedUsers={selectedUsers}
                                listOfAllUsers={listOfAllUsers}
                                selectedHomes={selectedHomes}
                                listOfAllHomes={listOfAllHomes}
                                setDisplayUsersModale={setDisplayUsersModale}
                                setDisplayHomesModale={setDisplayHomesModale} />

            <ClearSeparation />
            
            {/* RecapView */}
            <Pressable style={styles.tableauTitreContainer} onPress={(e) => {e.preventDefault(); setDisplayPart(displayPart === "recap" ? "" : "recap");}}>
                <Text style={styles.tableauTitre}>Récapitulatif</Text>
                {displayPart === "recap" ?
                    <MaterialIcons name="unfold-less" size={24} color={css_darkBlue} /> :
                    <MaterialIcons name="unfold-more" size={24} color={css_darkBlue} />}
            </Pressable>
            
            {!loading ?
            <>
            {displayPart === "recap" ?
                <RecapView  recapitulatifListe={recapitulatifListe}
                            enableUser={enableUser}
                            enableLogement={enableLogement}
                            selectedBegin={selectedBegin} 
                            selectedEnd={selectedEnd} 
                            selectedWorkflows={selectedWorkflows} 
                            selectedUsers={selectedUsers}
                            listOfAllUsers={listOfAllUsers} 
                            listOfAllHomes={listOfAllHomes}
                            selectedHomes={selectedHomes}
                            seen_by={seen_by} /> 
            : <></>}

            <ClearSeparation />
                
            {/* RecapTable */}
            <Pressable style={styles.tableauTitreContainer} onPress={(e) => {e.preventDefault(); setDisplayPart(displayPart === "tableau" ? "" : "tableau");}}>
                <Text style={styles.tableauTitre}>Voir le détail</Text>
                {displayPart === "tableau" ?
                    <MaterialIcons name="unfold-less" size={24} color={css_darkBlue} /> :
                    <MaterialIcons name="unfold-more" size={24} color={css_darkBlue} />}
            </Pressable>
            

            {displayPart === "tableau" ?
                <RecapTable recapitulatifTableau={recapitulatifTableau}
                            selectedUsers={selectedUsers}
                            selectedHomes={selectedHomes} 
                            selectedWorkflows={selectedWorkflows} 
                            selectedBegin={selectedBegin} 
                            selectedEnd={selectedEnd} 
                            listOfAllHomes={listOfAllHomes} 
                            listOfAllUsers={listOfAllUsers}
                            seen_by={seen_by} />
            :<></>}

            {/* Loading */}
                        
            {loading ?
                <View style={styles.container}>
                    <View   style={styles.middleofpage}>
                    <Titre  texte="Chargement en cours ..." 
                            size={1.5}
                            color={css_darkBlue}
                            fontWeight={700}
                            align='center'/>
                    </View>
                </View>
            : <></>}

            </>: <></>}</>}

            {/* Modales de filtre */}
            {displayUsersModale ?
                <UsersRecapModale   disappear={() => {setDisplayUsersModale(false)}} 
                                    onValidation={false}
                                    selectedUsers={selectedUsers}
                                    setSelectedUsers={setSelectedUsers}
                                    listOfAllUsers={listOfAllUsers}/>
            : <></>}
            {displayHomesModale ?
                <HomesRecapModale   disappear={() => {setDisplayHomesModale(false)}} 
                                    selectedHomes={selectedHomes}
                                    setSelectedHomes={setSelectedHomes}
                                    listOfAllHomes={listOfAllHomes}/>
            : <></>}
        </View>
    );
}

const styles = StyleSheet.create({ 
    middleofpage: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    container: {
        width: Math.min(screenWidth, 500),
        backgroundColor: css_white,
        minHeight: "100vh"
    },
    navbar: {
        width: Math.min(screenWidth, 500),
        height: "7.5vh",
        backgroundColor: css_darkBlue
    },
    navbarLink: {
        marginLeft: "2vw",
        marginTop: "2vh"
    },
    tableauTitreContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "flex-start",
        paddingHorizontal: (100-75)/2+'%',
        width: "100%",
        paddingVertical: "1vh",
        flexDirection: "row"
    },
    tableauTitre: {
        fontWeight: "700",
        color: css_darkBlue,
        fontSize: "1.1rem"
    },
    addMarginLeft: {
        marginLeft: "2vw"
    }
});
