import { StyleSheet, View, Text } from 'react-native';
import { useEffect, useState } from 'react';
import { css_green, css_grey, css_red, css_white } from '../../constants/Colors';
import AdminLink from '../Elements/AdminLink';
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import attribution_service from '../../services/attribution.service';
import logement_service from '../../services/logement.service';
import AddBadge from '../Elements/AddBadge';
import SelecteurDroits from '../Elements/SelecteurDroits';
import AjoutAttributions from '../Logement/AjoutAttributions';
import InputComponent from '../InputComponent';
import ClearSeparation from '../Elements/ClearSeparation';
import DeleteWorkflowModale from '../Modales/DeleteWorkflowModale';
import { screenWidth } from '../../constants/Const';

export default function ManageLogements({selectedLogement=undefined, setSelectedLogement=() => true, setAction=() => true, view="liste"}) {
    const [logementsList, setLogementsList] = useState([])
    const [infos, setInfos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [add, setAdd] = useState(false);
    const [listOfUsers, setListOfUsers] = useState([])
    const [search, setSearch] = useState("");
    const [onValidation, setOnValidation] = useState(false);

    useEffect(() => {
        refresh();
    }, []);

    useEffect(() => {
      refresh()  
    }, [view, add])

    const refresh = () => {
        setLoading(true);
        if (view === "attributions") {
            attribution_service.getPersonnel(selectedLogement.id_logement).then((y) => {
                setInfos(y);
                let tmp = [];
                for (let i = 0; i < y.length; i++) {
                    for(let j = 0; j < y[i].personnel.length; j++) {
                        let found = false;
                        tmp.forEach((t) => {
                            if (t.id_utilisateur === y[i].personnel[j].id_utilisateur)
                                found = true
                        })
                        if (!found)
                            tmp.push(y[i].personnel[j])
                    }
                }
                
                setListOfUsers(tmp)
                setLoading(false)
            })
        } else {
            logement_service.getLogements()
            .then((r) => {
                setLogementsList(r)
                setLoading(false)
            })
        }
        
    }

    const update = ({id_utilisateur, id_workflow = undefined, libelle = ""}) => {
        setLoading(true)
        if (id_workflow !== undefined) {
            attribution_service.updateAttribution(selectedLogement.id_logement, id_utilisateur, id_workflow)
            .then((res) => {
                refresh();
            })
        } else {
            if (libelle === "Nestors") {
                attribution_service.deleteNestor(id_utilisateur, selectedLogement.id_logement)
                .then((res) => {
                    refresh()
                })
            } else {
                attribution_service.deleteProprietaire(id_utilisateur, selectedLogement.id_logement)
                .then((res) => {
                    refresh()
                })
            }
            
        }
        
    }

    const deletion = (id_logement) => {
        setLoading(true);
        logement_service.deleteLogement(id_logement)
        .then((r) => {
            setOnValidation(false);
            setLoading(false);
            refresh()
        })
    }

    if (loading) return (<Text>Chargement en cours ...</Text>)
    if (view === "attributions") {
        return(
            <View   style={styles.links_ctn}>
            <AddBadge   setDisplayModale={() => {setAdd(true)}}/>
            {add ? 
                <AjoutAttributions  idLogement={selectedLogement.id_logement}
                                    alreadyAttributed={listOfUsers}
                                    informations={infos}
                                    goBack={() => {setAdd(false)}} /> 
            : 
            <>
            
                { infos.map((p, key) => {
                    return (
                        <View style={styles.role} key={`Informations${key}`}>
                            <Text style={styles.utilisateur}>{`${p.id_workflow !== undefined ? "Workflow : ":""}${p.libelle}`}</Text>
                        
                            {p.personnel.map((d, keyp) => {
                                return (
                                    <View style={styles.droit} key={`InformationsPersonneld${keyp}`}>
                                        <Text   style={{color: css_grey}}>{d.utilisateur}</Text>
                                        <SelecteurDroits    id_utilisateur={d.id_utilisateur}
                                                            libelle={p.libelle}
                                                            id_workflow={p.id_workflow}
                                                            id_logement={p.id_logement}
                                                            onUpdate={update} />
                                    </View>)
                            })}
                        </View>
                    )
                }) }</>}

            </View>
            
        );
    }
    return (
    <>
        <View   style={styles.links_ctn}>
            <AddBadge   setDisplayModale={() => {setAction("logements-add")}}/>
            <View   styles={styles.search_ctn}>
                <InputComponent     libelle={"Rechercher un logement"}
                                    onChange={setSearch}
                                    color={css_grey}
                                    />
            </View>
            <ClearSeparation />
            {logementsList
            .filter(
                l => search === "" 
                            || l.libelle.toLowerCase().includes(search.toLowerCase()) 
                            || l.numero_adresse.toLowerCase().includes(search.toLowerCase())
                            || l.type_voie_adresse.toLowerCase().includes(search.toLowerCase())
                            || l.nom_voie_adresse.toLowerCase().includes(search.toLowerCase())
                            || l.ville_adresse.toLowerCase().includes(search.toLowerCase())
                            || l.code_postal_adresse.toLowerCase().includes(search.toLowerCase()))
            .map((l, key) => {
                return (
                    <AdminLink  key={`k${l.libelle}${key}`}
                                titre={l.libelle}
                                actionOneIcon={<AntDesign name="delete" size={24} color={css_red}/>}
                                actionOne={() => {setSelectedLogement(l); setOnValidation(true)}}
                                actionTwoIcon={<AntDesign name="form" size={24} color={css_grey}/>}
                                actionTwo={() => {setAction("logements-update"); setSelectedLogement(l)}}
                                actionThreeIcon={<MaterialIcons name="person" size={24} color={css_grey}/>}
                                actionThree={() => {setAction("logements-attribution"); setSelectedLogement(l)}}/>
                )
            })}
            
        </View>
        {onValidation ? 
          <DeleteWorkflowModale onValidation={() => {deletion(selectedLogement.id_logement)}}
                                disappear={() => {setOnValidation(false)}}/> :<></>}
    </>
    );
    
}

const styles = StyleSheet.create({
    links_ctn: {
        marginTop: "3vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column"
    },
    role: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingRight:"5%",
        paddingLeft: "5%",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: css_grey,
        borderRadius: "15px",
        marginHorizontal: Math.min(screenWidth*0.05, 500*0.05),
        marginVertical: "1vh",
        width: Math.min(screenWidth*0.9, 500*0.9)
    },
    utilisateur: {
        fontWeight: "400",
        fontSize: "1.1rem",
        marginVertical: "1vh"
    },
    droit: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginVertical: "1vh"
    }
});