import moment from "moment-timezone";
import { del, get, post } from "../modules/ms-api.module";

const getFuturesProgrammations = async function (id_logement, seen_by = undefined) {
    const parameters = [{"id_logement": `${id_logement}`}];
    if (seen_by !== undefined) parameters.push({"seen_by": seen_by})
    const brut = await get({
        need_token: true,
        parameters: parameters,
        uri: "/programmation/future_programmation"
    });
    const data = await brut.json();
    if (brut.status === 200) return data.result;
    else return [];
}

const getTachesExecutablesFromProgrammation = async function(id_logement) {
    const brut = await get({
        need_token: true,
        uri: "/workflow/programmation_possible",
        parameters: [{"id_logement": `${id_logement}`}]
    })
    const data = await brut.json();
    if (brut.status === 200) return data.result;
    else return [];
}
const addTacheInProgrammation = async function (finalObject) {
    const brut = await post({
        need_token: true,
        uri: "/programmation/ajout_programmation",
        body: {
            id_logement: `${finalObject.id_logement}`,
            id_etape: `${finalObject.id_etape}`,
            id_workflow: `${finalObject.id_workflow}`,
            date_echeance: `${moment(finalObject.date_echeance).tz("Europe/Paris").format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`
        }
    });
    return brut.status === 200;
}
const deleteProgrammation = async function(finalObject) {
    const brut = await del({
        need_token: true,
        uri: "/programmation/",
        body: {
            scheduled: `${finalObject.scheduled}`,
            id_programmation: `${finalObject.id_programmation}`,
            id_workflow: `${finalObject.id_workflow}`,
            id_logement: `${finalObject.id_logement}`
        }
    });
    return brut.status === 200;
}
const getFiltreEcheance = async function(seen_by = undefined) {
    let brut = undefined;
    if (seen_by === undefined) {
        brut = await get({
            need_token: true,
            uri: "/programmation/compter_par_echeance"
        });
    }
    else {
        brut = await get({
            need_token: true,
            uri: "/programmation/compter_par_echeance",
            parameters: [{"seen_by": seen_by}]
        });
    }
    const data = await brut.json();
    return data.result
}

const getDestinataires = async function(id_programmation = -1) {
    if (id_programmation === -1) return "Il manque le paramètre id_programmation."
    const brut = await get({
        need_token: true,
        uri: "/programmation/destinataires",
        parameters: [{"id_programmation" : `${id_programmation}`}]
    });
    const data = await brut.json();
    return data.result;
}
const getRecapitulatif = async function(date_debut, date_fin, filter_people = undefined, filter_house = undefined, seen_by = undefined) {
    const parameters = [
        {"date_debut": `${moment(date_debut).tz("Europe/Paris").format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`},
        {"date_fin": `${moment(date_fin).tz("Europe/Paris").format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`}
    ];
    if (seen_by !== undefined) parameters.push({"seen_by": seen_by});
    if (filter_people !== undefined)
        parameters.push({"filter_people": `${filter_people.length === 0 ? '-1' : filter_people.join(',')}`});
    if (filter_house !== undefined)
        parameters.push({"filter_house": `${filter_house.length === 0 ? '-1' : filter_house.join(',')}`});

    const brut = await get({
        need_token: true,
        parameters: parameters,
        uri : "/programmation/recapitulatif"
    });
    const data = await brut.json()
    return data.result;
}
const downloadRecapitulatifAsPDF = async function (date_debut, date_fin, filter_workflow = -1, filter_people = undefined, filter_house = undefined, enable_house = true, enable_people = true, seen_by = undefined) {
    const query = [
        {"date_debut": `${moment(date_debut).tz("Europe/Paris").format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`},
        {"date_fin": `${moment(date_fin).tz("Europe/Paris").format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`},
        {"filter_workflow": filter_workflow},
        {"enable_house": !enable_house ? "false" : "true"},
        {"enable_people": !enable_people ? "false" : "true"}
    ]
    if (seen_by !== undefined) query.push({"seen_by": seen_by});
    if (filter_people !== undefined)
        query.push({"filter_people":  filter_people.length === 0 ? '-1' : filter_people.join(',')});
    if (filter_house !== undefined)
        query.push({"filter_house": filter_house.length === 0 ? '-1' : filter_house.join(',')});

    const brut = await get({
        need_token: true,
        parameters: query,
        uri: "/programmation/recapitulatif/pdf" 
    });
    if (brut.ok) {
        const blob = await brut.blob();
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = 'recapitulatif.pdf';
        //downloadLink.target = '_blank';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    } else {
        console.log("Error on PDF download... oops");
    }
}
const downloadRecapitulatifAsCSV = async function (date_debut, date_fin, filter_workflow = -1, filter_people = undefined, filter_house = undefined, seen_by = undefined) {
    const query = [
        {"date_debut": `${moment(date_debut).tz("Europe/Paris").format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`},
        {"date_fin": `${moment(date_fin).tz("Europe/Paris").format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`},
        {"filter_workflow": filter_workflow}
    ]
    
    if (seen_by !== undefined) query.push({"seen_by": seen_by});
    if (filter_people !== undefined)
        query.push({"filter_people": filter_people.length === 0 ? '-1' : filter_people.join(',')});
    if (filter_house !== undefined)
        query.push({"filter_house": filter_house.length === 0 ? '-1' : filter_house.join(',')});

    const brut = await get({
        need_token: true,
        uri: "/programmation/recapitulatif/csv",
        parameters: query,
    });
    if (brut.ok) {
        const blob = await brut.blob();
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        
        downloadLink.download = 'recapitulatif.csv';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    } else {
        console.log("Error on CSV download... oops");
    }
}
const programmation_service = {getDestinataires, getFuturesProgrammations, getTachesExecutablesFromProgrammation, addTacheInProgrammation, deleteProgrammation, getRecapitulatif, downloadRecapitulatifAsCSV, downloadRecapitulatifAsPDF, getFiltreEcheance};
export default programmation_service;