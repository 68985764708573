import { StyleSheet, View, Text } from 'react-native';
import { useEffect, useState } from 'react';
import { css_black, css_darkBlue, css_green, css_grey, css_red } from '../../constants/Colors';
import AdminLink from '../Elements/AdminLink';
import { AntDesign, Entypo, FontAwesome, Ionicons } from '@expo/vector-icons';
import utilisateur_service from '../../services/utilisateur.service';
import ClearSeparation from '../Elements/ClearSeparation';
import InputComponent from '../InputComponent';
import AddBadge from '../Elements/AddBadge';
import attribution_service from '../../services/attribution.service';
import Titre from '../Titre';
import DeleteWorkflowModale from '../Modales/DeleteWorkflowModale';

export default function ManageAdministrateurs({setAction=() => true}) {
    const [adminsList, setAdminsList] = useState([])
    const [usersList, setUsersList] = useState([])
    const [loading, setLoading] = useState(true);
    const [selectedAdmin, setSelectedAdmin] = useState(undefined);
    const [search, setSearch] = useState("");
    const [onValidation, setOnValidation] = useState(false);

    useEffect(() => {
        refresh()
    }, []);

    const refresh = () => {
        setLoading(true);
        attribution_service.getAdministrateurs()
        .then((r) => {
            setAdminsList(r)
            utilisateur_service.getUtilisateurs()
            .then((u) => {
                setUsersList(u.filter(user=> r.filter(admin => admin.id_utilisateur === user.id_utilisateur).length === 0))
                setLoading(false)
            })
        })
    }

    const destitution = (id_utilisateur) => {
        setLoading(true);
        attribution_service.destituer(id_utilisateur)
        .then((r) => {
            setOnValidation(false)
            refresh()
            setSearch("")
            setLoading(false)
        })
    }

    const add = (id_utilisateur) => {
        setLoading(true);
        attribution_service.add(id_utilisateur)
        .then((r) => {
            refresh()
            setSearch("")
            setLoading(false)
        })
    }

    if (loading) return (<Text>Chargement en cours ...</Text>)
    
    return (
    <>
        <View   style={styles.links_ctn}>
            <View   styles={styles.search_ctn}>
                <InputComponent     libelle={"Rechercher un administrateur"}
                                    onChange={setSearch}
                                    color={css_grey}
                                    />
            </View>
            <ClearSeparation />
            <Titre  texte={"Liste des administrateurs"}
                    marginLeft={5} 
                    size={1.1}
                    color={css_black}
                    marginBottom={2}
                    align='center'/>
            {adminsList.filter(u => search === "" 
                            || u.nom.toLowerCase().includes(search.toLowerCase()) 
                            || u.prenom.toLowerCase().includes(search.toLowerCase())
                            || u.mail.toLowerCase().includes(search.toLowerCase())
                            || u.utilisateur.toLowerCase().includes(search.toLowerCase())
                            || u.phone.includes(search)).map((u, key) => {
                return (
                    <AdminLink  key={`k${u.utilisateur}${key}`}
                                titre={`${u.nom} ${u.prenom}`}
                                actionOneIcon={<FontAwesome name="ban" size={24} color={css_red}/>}
                                actionOne={() => {setSelectedAdmin(u);setOnValidation(true)}} />
                )
            })}
            <ClearSeparation />
            
            <Titre  texte={"Liste des utilisateurs non administrateurs"}
                    marginLeft={5} 
                    size={1.1}
                    color={css_black}
                    marginBottom={2}
                    align='center' />
            {usersList.filter(u => search === "" 
                            || u.nom.toLowerCase().includes(search.toLowerCase()) 
                            || u.prenom.toLowerCase().includes(search.toLowerCase())
                            || u.mail.toLowerCase().includes(search.toLowerCase())
                            || u.utilisateur.toLowerCase().includes(search.toLowerCase())
                            || u.phone.includes(search)).map((u, key) => {
                return (
                    <AdminLink  key={`k${u.utilisateur}${key}`}
                                titre={`${u.nom} ${u.prenom}`}
                                actionOneIcon={<Entypo name="add-user" size={24} color={css_green}/>}
                                actionOne={() => {add(u.id_utilisateur)}} />
                )
            })}
        </View>
        {onValidation ? 
          <DeleteWorkflowModale onValidation={() => {destitution(selectedAdmin.id_utilisateur)}}
                                disappear={() => {setOnValidation(false)}}/> :<></>}
    </>
    );
    
}

const styles = StyleSheet.create({
    links_ctn: {
        marginTop: "3vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%"
    },
    search_ctn: {
        marginTop: "3vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%"
    }
});