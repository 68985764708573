import { StyleSheet, View, Text } from 'react-native';
import { useEffect, useState } from 'react';
import { css_grey, css_red } from '../../constants/Colors';
import AdminLink from '../Elements/AdminLink';
import { AntDesign, Ionicons } from '@expo/vector-icons';
import utilisateur_service from '../../services/utilisateur.service';
import ClearSeparation from '../Elements/ClearSeparation';
import InputComponent from '../InputComponent';
import AddBadge from '../Elements/AddBadge';
import DeleteWorkflowModale from '../Modales/DeleteWorkflowModale';
import { screenWidth } from '../../constants/Const';

export default function ManageUtilisateurs({selectedUser=undefined, setSelectedUser=() => true, setAction=() => true}) {
    const [usersList, setUsersList] = useState([])
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [onValidation, setOnValidation] = useState(false);

    useEffect(() => {
        refresh()
    }, []);

    const refresh = () => {
        setLoading(true);
        utilisateur_service.getUtilisateurs()
        .then((r) => {
            setUsersList(r)
            setLoading(false)
        })
    }

    const deleteUser = (id_utilisateur) => {
        setLoading(true);
        utilisateur_service.deleteUtilisateur(id_utilisateur)
        .then((r) => {
            refresh()
            setSearch("")
            setLoading(false)
            setOnValidation(false)
        })
    }

    if (loading) return (<Text>Chargement en cours ...</Text>)
    
    return (
    <>
        <View   style={styles.links_ctn}>
            <AddBadge   setDisplayModale={() => {setAction("utilisateurs-add")}}/>
            <View   styles={styles.search_ctn}>
                <InputComponent     libelle={"Rechercher un utilisateur"}
                                    color={css_grey}
                                    onChange={setSearch}
                                    />
            </View>
            <ClearSeparation />
            {usersList.filter(u => search === "" 
                            || u.nom.toLowerCase().includes(search.toLowerCase()) 
                            || u.prenom.toLowerCase().includes(search.toLowerCase())
                            || u.mail.toLowerCase().includes(search.toLowerCase())
                            || u.utilisateur.toLowerCase().includes(search.toLowerCase())
                            || u.phone.includes(search)).map((u, key) => {
                return (
                    <AdminLink  key={`k${u.utilisateur}${key}`}
                                titre={`${u.nom} ${u.prenom}`}
                                actionOneIcon={<AntDesign name="delete" size={24} color={css_red}/>}
                                actionOne={() => {setSelectedUser(u);setOnValidation(true)}}
                                actionTwoIcon={<AntDesign name="form" size={24} color={css_grey}/>}
                                actionTwo={() => {setAction("utilisateurs-update"); setSelectedUser(u)}}
                                actionThreeIcon={<Ionicons name="home-outline" size={24} color={css_grey}/>}
                                actionThree={() => {setAction("utilisateurs-logement"); setSelectedUser(u)}} />
                )
            })}
            
        </View>
        {onValidation ? 
          <DeleteWorkflowModale onValidation={() => {deleteUser(selectedUser.id_utilisateur)}}
                                disappear={() => {setOnValidation(false)}}/> :<></>}
    </>
    );
    
}

const styles = StyleSheet.create({
    links_ctn: {
        marginTop: "3vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: Math.min(screenWidth, 500)
    },
    search_ctn: {
        marginTop: "3vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: Math.min(screenWidth, 500)
    }
});