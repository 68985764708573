import { StyleSheet, View, Text } from 'react-native';
import { useEffect, useState } from 'react';
import { css_bg_grey, css_bg_red, css_darkBlue, css_green, css_grey, css_lightBlue, css_red, css_white } from '../../constants/Colors';
import AdminLink from '../Elements/AdminLink';
import { AntDesign, Ionicons } from '@expo/vector-icons';
import utilisateur_service from '../../services/utilisateur.service';
import ClearSeparation from '../Elements/ClearSeparation';
import InputComponent from '../InputComponent';
import AddBadge from '../Elements/AddBadge';
import Button from '../Button';
import Titre from '../Titre';

export default function FormUtilisateur({onBack, toUpdate_nom = "", toUpdate_prenom = "", toUpdate_mail = "", toUpdate_telephone = "", toUpdate_id_utilisateur = undefined, actual_username = ""}) {
    const [loading, setLoading] = useState(false);
    const [nom, setNom] = useState("");
    const [prenom, setPrenom] = useState("");
    const [mail, setMail] = useState("");
    const [telephone, setTelephone] = useState("");
    const [creationResult, setCreationResult] = useState(undefined);

    useState(() => {
        if (toUpdate_id_utilisateur !== undefined) {
            setNom(toUpdate_nom);
            setPrenom(toUpdate_prenom);
            setMail(toUpdate_mail);
            setTelephone(toUpdate_telephone);
        }
    }, [toUpdate_nom, toUpdate_prenom, toUpdate_mail, toUpdate_telephone, toUpdate_id_utilisateur])


    const exec = () => {
        if (toUpdate_id_utilisateur !== undefined) {
            utilisateur_service.updateUtilisateur(nom,prenom,mail,telephone,toUpdate_id_utilisateur)
            .then((r) => {
                onBack()
            })
        } else {
            utilisateur_service.createUtilisateur(nom,prenom,mail,telephone)
            .then((r) => {
                setCreationResult(r.result)
                setNom("");
                setPrenom("");
                setMail("");
                setTelephone("");
            })
        }
    }

    const reset = () => {
        utilisateur_service.resetPassword(toUpdate_id_utilisateur)
        .then((r) => {
            setCreationResult(r.result)
        })
    }

    if (loading) return (<Text>Chargement en cours ...</Text>)
    return (
        <View   style={styles.ctn}>
            <View   style={styles.form_ctn}>
                {creationResult !== undefined ?
                <View>
                    <Titre  texte={creationResult.identifiant === undefined ? "Mot de passe réinitialisé" : "Nouvel utilisateur créé !"}/>
                    <Text>Identifiant : {creationResult.identifiant === undefined ? actual_username : creationResult.identifiant}</Text>
                    <Text>Mot de passe : {creationResult.mot_de_passe}</Text>
                    <ClearSeparation />
                </View>:<></>}
                {toUpdate_id_utilisateur !== undefined ? 
                    <InputComponent libelle="Username" 
                                    disable={true}
                                    background={css_bg_grey}
                                    color={css_grey}
                                    current_value={actual_username}
                    />
                    : <></>
                }
                <InputComponent libelle="Nom de l'utilisateur (*)" 
                                onChange={setNom}
                                disable={loading}
                                color={css_grey}
                                current_value={nom}
                                />
                <InputComponent libelle="Prénom de l'utilisateur (*)" 
                                onChange={setPrenom}
                                disable={loading}
                                color={css_grey}
                                current_value={prenom}
                                />  
                <InputComponent libelle="Adresse mail de l'utilisateur (*)" 
                                onChange={setMail}
                                disable={loading}
                                color={css_grey}
                                current_value={mail}
                                />
                <InputComponent libelle="Téléphone de l'utilisateur (*)" 
                                onChange={setTelephone}
                                disable={loading}
                                color={css_grey}
                                current_value={telephone}
                                />

                    
                    <Button onPress={exec}
                        title={loading ? "Création en cours ...": toUpdate_id_utilisateur !== undefined ? "Mettre à jour le compte" : "Créer le compte"}
                        color={css_white}
                        background={css_green}
                        fontWeight="700"
                        disabled={telephone === "" || mail === "" || prenom === "" || nom === ""}
                        accessibilityLabel="Bouton de validation de la créer du compte utilisateur."
                    />

                <ClearSeparation />
                {toUpdate_id_utilisateur !== undefined ?
                    <Button onPress={reset}
                            title={loading ? "Réinitialisation du mot de passe en cours ...": "Réinitialiser le mot de passe"}
                            color={css_white}
                            background={css_darkBlue}
                            fontWeight="700"
                            accessibilityLabel="Bouton de validation de la réinitialisation du mot de passe de l'utilisateur."
                        /> 
                    :<Text   style={styles.subtitle}>Le mot de passe et l'identifiant seront générés automatiquement et envoyé par mail / téléphone à l'utilisateur de ce nouveau compte.</Text>}
                
            </View>
            
        </View>
    );
    
}

const styles = StyleSheet.create({
    ctn: {
        marginTop: "3vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%"
    },
    form_ctn: {
        width:"90%"
    },
    search_ctn: {
        minWidth: "90%"
    },
    subtitle: {
        textAlign: "center"
    }
});