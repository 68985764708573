import { StyleSheet, Text, View, Image } from 'react-native';
import { css_darkBlue, css_white } from '../constants/Colors';
import { logoBanner } from '../constants/Image';
import { screenWidth } from '../constants/Const';

export default function Navbar({sidebarEnabled = false, setEnableSidebar = null}) {


  return (
        <View style={sidebarEnabled ? [styles.container, styles.enableSidebar] : styles.container}>

          <Image source={logoBanner} style={styles.titre} />
        </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: css_darkBlue,
    alignItems: 'center',
    justifyContent: 'center',
    height: "24vh",
    width: Math.min(screenWidth, 500)
  },
  titre: {
    width: Math.min(screenWidth*0.6, 500*0.6),
    height: Math.min(screenWidth*0.2, 500*0.2)
  }, 
  enableSidebar: {
    marginLeft: screenWidth <= 500 ? "-60vw" : 0
  }
});
