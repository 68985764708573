
import { useEffect, useState } from 'react';
import { StyleSheet, Text, View, Image, Pressable } from 'react-native';
import { useNavigate } from 'react-router-dom';
import {  css_bg_grey, css_green, css_grey, css_jaune, css_orange, css_red, css_white } from '../../constants/Colors';
import { sortbyTachesbyAlphabetic } from '../../constants/Sort';
import BadgeVacant from './BadgeVacant';
import { FontAwesome, Ionicons } from '@expo/vector-icons';
import { screenWidth } from '../../constants/Const';

export default function LogementAccueil({infos, filterEcheance = "none", filterWorkflow=-1, displayBadgeVacant= true, expandEnable = true, setExpandWorkflows = () => {}, expandedWorkflows = undefined, seen_by = undefined, setSelectedIdLogement = () => true, setDisplayLogement = () => true}) {
    const [lastTaches, setLastTaches] = useState([]);
    const [color, setColor] = useState(css_green);
    const [displayed, setDisplayed] = useState(true);

    const navigate = useNavigate();
    useEffect(() => {
      if (infos.workflows !== undefined) {
        let t = []
        if (filterWorkflow === -1) t = infos.workflows
        else t = infos.workflows.filter(ew => ew.id_workflow === filterWorkflow)

        if (filterEcheance === "none") {
          setColor(
            t.length === 0 ? css_white :
            t[0].case === "red" ? css_red : 
            t[0].case === "orange" ? css_orange : 
            t[0].case === "yellow" ? css_jaune :
            t[0].case === "grey" ? css_grey 
            : css_green)
          setLastTaches(t.sort(sortbyTachesbyAlphabetic))
        }
        else if (filterEcheance === "Aujourd'hui") {
          setColor(t.filter(ew => ew.case === "red").length === 0 ? t.filter(ew => ew.case === "green" || ew.case === "deleted").length === 0 ? css_white : css_green : css_red )
          setLastTaches(t.filter(ew => ew.case === "red" || ew.case === "green").sort(sortbyTachesbyAlphabetic))
        }
        else if (filterEcheance === "Demain") {
          setColor(t.filter(ew => ew.case === "orange").length === 0 ? css_white : css_orange )
          setLastTaches(t.filter(ew => ew.case === "orange").sort(sortbyTachesbyAlphabetic))
        }
        else if (filterEcheance === "Semaine") {
          setColor(t.filter(ew => ew.case === "grey").length === 0 ? css_white : css_grey )
          setLastTaches(t.filter(ew => ew.case === "grey").sort(sortbyTachesbyAlphabetic))
        }
      }
    }, [infos, filterEcheance, filterWorkflow])

    const goToLogementPage = (id_logement) => {
      if (seen_by === undefined) navigate(`/logement?which=${id_logement}`);
      else {
        setSelectedIdLogement(id_logement);
        setDisplayLogement(true);
      }
    }

    const styles = StyleSheet.create({
      global_ctn: {
        marginTop: "3vh",
        display: "grid"
      },
      firstRow_ctn: {
        marginHorizontal: 0,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap"
      },
      img_ctn: {
        width: Math.min(screenWidth*0.2, 0.2*500),
        height: Math.min(screenWidth*0.2, 0.2*500),
      },
      infos_ctn: {
        width: Math.min(screenWidth*0.65, 0.65*500),
        display: "flex",
        flexDirection: "column"
      },
      imageLogement: {
        width: Math.min(screenWidth*0.2, 0.2*500),
        height: Math.min(screenWidth*0.2, 0.2*500),
        borderColor: color,
        borderWidth: "0px"
      },
      container: {
        backgroundColor: css_white,
        alignItems: 'center',
        justifyContent: 'center',
        display: "flex",
        flexDirection: "column",
      },
      surnom: {
        backgroundColor: css_white,
        borderBottomLeftRadius: Math.min(screenWidth*0.04, 0.04*500),
        borderBottomRightRadius: Math.min(screenWidth*0.04, 0.04*500),
        textAlign: "left",
        fontSize: "1.3rem",
        fontWeight: "400",
        paddingHorizontal: Math.min(screenWidth*0.02, 0.02*500),
        fontWeight: "600"
      },
      lastTaches: {
        display: "flex",
        alignContent: "center",
        justifyContent: "flex-end",
        flexDirection: "row",
        paddingHorizontal:"5%"
      },
      scroll: {
        width: "100%"
      },
      listIcon: {
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "row",
        alignItems: "center"
      },
      pointGreen: {
        backgroundColor: css_green
      },
      pointGrey: {
        backgroundColor: css_grey
      },
      pointOrange: {
        backgroundColor: css_orange
      },
      pointRed: {
        backgroundColor: css_red
      },
      pointBlue: {
        backgroundColor: css_bg_grey
      },
      point: {
        backgroundColor: css_white,
        width: "4px",
        height: "100%",
        borderRadius: "15px"
      },
      pointCtn: {
        width:Math.min(screenWidth*0.03, 0.03*500),
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start"
      },
      titleCtn: {
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "row"
      },
      iconsAction: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignContent: "flex-end",
        alignItems: "center"
      },
      iconExpend: {
        fontSize: "1.2rem",
        marginTop: "1vh",
        marginRight: "1vw"
      },
      expandIconCtn : {
        paddingVertical: "1vh",
        paddingHorizontal: "1vw"
      }
    });
    

  return (displayed ? 
    <View style={styles.global_ctn}>
      <View style={styles.firstRow_ctn}>
        
        <View style={styles.pointCtn}>
          {infos.workflows !== undefined ? 
          <View style={[styles.point, infos.workflows.length === 0 ? styles.pointBlue :                                     
                          filterEcheance === "Aujourd'hui" && infos.workflows.filter(ew => ew.case === "red" && (filterWorkflow === -1 || filterWorkflow === ew.id_workflow) ).length !== 0  
                          ? styles.pointRed : filterEcheance === "Aujourd'hui" && infos.workflows.filter(ew => ew.case === "green" && (filterWorkflow === -1 || filterWorkflow === ew.id_workflow) ).length !== 0
                          ? styles.pointGreen : filterEcheance === "Demain" && infos.workflows.filter(ew => ew.case === "orange" && (filterWorkflow === -1 || filterWorkflow === ew.id_workflow) ).length !== 0
                          ? styles.pointOrange : filterEcheance === "Semaine" && infos.workflows.filter(ew => ew.case === "grey" && (filterWorkflow === -1 || filterWorkflow === ew.id_workflow) ).length !== 0
                          ? styles.pointGrey : styles.pointBlue]}></View>
          :<></>}
        </View>

        <Pressable onPress={() => {goToLogementPage(infos.id_logement)}}
                    style={styles.img_ctn}>
          <Image  source={{uri: infos.photo}} 
                  style={styles.imageLogement} />

        </Pressable>
        <View style={styles.infos_ctn}>
          <View style={styles.titleCtn}>
            <Pressable onPress={() => {goToLogementPage(infos.id_logement)}}>
              <Text style={styles.surnom}>{infos.libelle}</Text>
            </Pressable>
          </View>
          
          <View style={styles.iconsAction}>
            {displayBadgeVacant ? 
              <BadgeVacant  isCheckin={infos.est_vacant[filterEcheance].checkin} 
                            isCheckout={infos.est_vacant[filterEcheance].checkout} 
                            occupied={infos.est_vacant[filterEcheance].occupied} />
              : <></>}

            <Pressable onPress={setExpandWorkflows}
                style={styles.expandIconCtn}>
              {expandEnable ? <FontAwesome name={expandedWorkflows? "angle-double-up" : "angle-double-down"} style={styles.iconExpend} color="black" /> : <></>}
            </Pressable>
          </View>
          
        </View>
        
        
      </View>
      
    </View> : <></>
  );
}

