import { StyleSheet, View, Text } from 'react-native';
import { useState } from 'react';
import Header from '../components/Header';
import Button from './Button';
import InputComponent from './InputComponent';
import auth_service from '../services/auth.service';
import { css_lightBlue, css_white } from '../constants/Colors';
import { screenWidth } from '../constants/Const';

export default function Inscription({onBack, launchConnexion}) {
    const [toSend, setToSend] = useState({
        utilisateur: "",
        mot_de_passe: "",
        phone: null,
        mail: null
    })
    const [loading, setLoading] = useState(false);
    const [displayMessage, setDisplayMessage] = useState(false)
    const [formIsValid, setFormIsValid] = useState(false);

    const [mailIsValid, setMailIsValid] = useState(true);
    const [utilisateurIsValid, setUtilisateurIsValid] = useState(true);

    const utilisateurChanged = (newUtilisateur) => {
        const tmp = toSend;
        toSend.utilisateur = newUtilisateur.toLowerCase();
        setToSend(tmp)
        utilisateurEstValide(newUtilisateur.toLowerCase())
    }
    const utilisateurEstValide = (newUtilisateur) => {
        const regex = new RegExp(/^[A-Za-z0-9@.]{5,}$/i);
        setUtilisateurIsValid(regex.test(newUtilisateur))
        setFormIsValid(regex.test(newUtilisateur)&&mailIsValid)
    }

    const mailEstValide = (newMail) => {
        if (newMail === "") {
            setMailIsValid(true);
            setFormIsValid(utilisateurIsValid)    
        } else {
            const regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/i);
            setMailIsValid(regex.test(newMail))
            setFormIsValid(regex.test(newMail)&&utilisateurIsValid)    
        }
    }

    const mdpChanged = (newMdp) => {
        const tmp = toSend;
        toSend.mot_de_passe = newMdp;
        setToSend(tmp)
    }

    const mailChanged = (newMail) => {
        const tmp = toSend;
        toSend.mail = newMail === "" ? null : newMail;
        setToSend(tmp)
        mailEstValide(newMail)
    }

    const phoneChanged = (newPhone) => {
        const tmp = toSend;
        toSend.phone = newPhone === "" ? null : newPhone;
        setToSend(tmp)
    }

    const goToAccueil = () => {
        setLoading(true);
        auth_service.inscription(toSend).then((res) => {
            setLoading(false);
            if (res) {
                launchMessage()
            }
        })
        
    }

    const launchMessage = () => {
        setDisplayMessage(true);
        setTimeout(() => {
            setDisplayMessage(false)
            launchConnexion();
        }, 3000)
    }

    const retour = () => {
        onBack();
    }

    if (displayMessage) {
        return (
            <View style={styles.container}>
                <Header onBack={retour} />
                <View style={styles.checkContainer}>
                    <Text style={styles.messagedone}>Inscription réussie !</Text>
                    <Text style= {styles.messagedoneSub}>Redirection vers l'écran de connexion en cours ...</Text>
                </View>
            </View>
        )
    } else {
        return (
            <View style={styles.container}>
                <Header onBack={retour} />
                <View style={styles.checkContainer}>
                    <Text style={styles.messagedone}>Création d'un compte</Text>
                </View>
                <View style={styles.form} >
                    <View style={styles.taille}>
                        <InputComponent libelle="Nom d'utilisateur" 
                                        onChange={utilisateurChanged}
                                        disable={loading}
                                        />
                        <InputComponent libelle="Mot de passe" 
                                        onChange={mdpChanged}
                                        inputType="password" 
                                        disable={loading}
                                        />
                    </View>
                </View>
                <View style={styles.checkContainer}>
                    <Text style={styles.messagedone}>Notifications (optionnel)</Text>
                </View>
                <View style={styles.form} >
                    <View style={styles.taille}>
                        <InputComponent libelle="Adresse mail" 
                                        onChange={mailChanged}
                                        disable={loading}
                                        />
                        <InputComponent libelle="Numéro de téléphone" 
                                        onChange={phoneChanged} 
                                        disable={loading}
                                        />
                        {formIsValid ?
                            <Button onPress={goToAccueil}
                                title={loading ? "Inscription en cours ...": "Je m'inscris"}
                                color={css_white}
                                background={css_lightBlue}
                                fontWeight="700"
                                marginBottom="5vw"
                                accessibilityLabel="Bouton de validation de la connexion."
                            /> : <></>}
                    </View>
                </View>
            </View>
          );
    }
    
    }
    
    const styles = StyleSheet.create({
        container: {
            width: Math.min(500, screenWidth),
            minHeight: "100vh",
            backgroundColor: css_white
        },
        form: {
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            width: "100%"
        },
        taille: {
            width: "90%"
        },
        check: {
          width: "30vw",
          height: "30vw",
          margin: "5vw"
        },
        checkContainer: {
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          marginVertical: "5vh"
        },
        messagedone: {
            fontSize: "1.4rem",
        },
        messagedoneSub: {
            fontSize: "0.9rem",
            fontStyle: "italic"
        }
    });