import { View, StyleSheet, Text, Pressable } from "react-native";
import Titre from "../Titre";
import BadgeVacant from "../Elements/BadgeVacant";
import Workflow from "../Elements/Workflow";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { css_bg_grey, css_green, css_grey, css_orange, css_red, css_white } from "../../constants/Colors";
import { useEffect } from "react";
import moment from "moment-timezone";
import { LinearGradient } from "expo-linear-gradient";
import LogementAccueil from "../Elements/LogementAccueil";
import { screenWidth } from "../../constants/Const";

export default function ListeParWorkflows({
    display = true, 
    echeance = "Aujourd'hui", 
    workflows=[], 
    selectedWorkflow = -1, 
    setSelectedAction = null , 
    setLoadingProgrammation =  null, 
    loadingProgrammation = -1,
    setSelectedTache = null, 
    selectedTache = undefined,
    setSelectedIdLogement = () => true,
    setSelectedIdProgrammation = () => true,
    setDisplayLogement = () => true,
    seen_by = undefined
}) {
    const navigate = useNavigate();
    const [ew, setEtatsWorkflow] = useState([]);
    const [expendLogement, setExpendLogement] = useState(-1);
    const [extendedProgrammation, setExtendedProgrammation] = useState([]);
    const [expandedWorkflows, setExpandWorkflows] = useState(undefined);
    const goToLogementPage = (id_logement) => {
        if (seen_by === undefined) navigate(`/logement?which=${id_logement}`);
        else {setDisplayLogement(true); setSelectedIdLogement(id_logement);}
    }
    useEffect(() => {
        setExpendLogement(-1);
        let tmp = undefined;
        // filtre sur date
        if (echeance === "Aujourd'hui") 
            tmp = workflows.filter(sw => moment(sw.date).tz("Europe/Paris").isSameOrBefore(moment.tz("Europe/Paris"), "day"))
        else if (echeance === "Demain") 
            tmp = workflows.filter(sw => moment(sw.date).tz("Europe/Paris").isSame(moment.tz("Europe/Paris").add(1, 'day'), "day"))
        else if (echeance === "Semaine") 
            tmp = workflows.filter(sw => moment(sw.date).tz("Europe/Paris").isSameOrBefore(moment.tz("Europe/Paris").add(7,"days"), "day") 
                                    && moment(sw.date).tz("Europe/Paris").isAfter(moment.tz("Europe/Paris").add(1, 'day'), "day"))   
        
        if (selectedWorkflow !== -1) {
            let final = [];
            for (let i = 0; i < tmp.length; i++) {
                let log = undefined;
                log = {date: tmp[i].date, logements: []};
                for (let j = 0; j < tmp[i].logements.length; j++) {
                    const workflows = tmp[i].logements[j].workflows.filter((l) => l.id_workflow === selectedWorkflow)
                    if (workflows.length > 0) {
                        log.logements.push(tmp[i].logements[j])
                    }
                }
                if (log.logements.length > 0)
                    final.push(log)
            }
            setEtatsWorkflow(final)
        } else {
            
            setEtatsWorkflow(tmp)
        }
        
    }, [selectedWorkflow, echeance, loadingProgrammation, workflows])

    useEffect(() => {
        setExpendLogement(-1);
    }, [display]);

    if (!display) {
        return (
            <></>
        );
    } else {
        return (
            <View style={styles.container}>
                {ew.map((res, key) => {
                return (
                    <View   key={`ListeWorkflows${res.date}`} 
                            style={styles.dayName}>
                        <Titre  texte={`${moment(res.date).tz("Europe/Paris").format("dddd D MMMM YYYY")[0].toUpperCase()}${moment(res.date).tz("Europe/Paris").format("dddd D MMMM YYYY").substring(1)}`}
                                fontWeight={500}
                                size={1.1}
                                marginLeft={3} />
                        {res.logements.map((l) => { 
                            if ((l.state === "green" || l.state === "deleted") && l.workflows.filter(w => w.id_workflow === selectedWorkflow || selectedWorkflow === -1).length > 1 && expendLogement !== l.id_logement && echeance === "Aujourd'hui") {
                                
                                return ( 
                                <View key={`ListeWorkflows${res.date}${l.id_logement}`}
                                        style={styles.containerLogement} >
                                    <View style={styles.logementName}>
                                        <LogementAccueil    infos={l} 
                                                            setExpandWorkflows={() => {
                                                                setExpendLogement(l.id_logement);
                                                                setExpandWorkflows("l"+l.id_logement+"d"+moment(l.workflows[0].date_echeance).tz("Europe/Paris").format("YYYY-MM-DD") != expandedWorkflows 
                                                                ? "l"+l.id_logement+"d"+moment(l.workflows[0].date_echeance).tz("Europe/Paris").format("YYYY-MM-DD") 
                                                                : undefined)}}
                                                            expandedWorkflows={false}
                                                            filterWorkflow={selectedWorkflow}
                                                            filterEcheance={echeance}
                                                            setSelectedIdLogement={setSelectedIdLogement}
                                                            setDisplayLogement={setDisplayLogement}
                                                            seen_by={seen_by}
                                                            />
                                    </View>
                                    <Workflow   destinataires={l.workflows[0].destinataire} 
                                                display={selectedWorkflow === -1 || l.workflows.filter((w) => w.id_workflow === selectedWorkflow).length !== 0}
                                                libelle={l.workflows[0].workflow}
                                                color={l.workflows[0].case}
                                                date_change={l.workflows[0].date_change}
                                                date_echeance={l.workflows[0].date_echeance}
                                                selected={false}
                                                extended={false}
                                                id_logement={l.id_logement}
                                                validateur={l.workflows[0].validateur}
                                                nom_createur={l.workflows[0].createur}
                                                loading={loadingProgrammation === l.workflows[0].id_programmation}
                                                setSelectedIdLogement={setSelectedIdLogement}
                                                setSelectedIdProgrammation={setSelectedIdProgrammation}
                                                setDisplayLogement={setDisplayLogement}
                                                seen_by={seen_by}
                                                onBack={() => {setSelectedTache(undefined); setSelectedAction(undefined)}}
                                                onValidation={() => {setSelectedTache(selectedTache === undefined ? l.workflows[0] : l.workflows[0].id_workflow === selectedTache.id_workflow ? undefined : l.workflows[0]); setSelectedAction("next")}}
                                                onDeletion={() => {setSelectedTache(selectedTache === undefined ? l.workflows[0] : l.workflows[0].id_workflow === selectedTache.id_workflow ? undefined : l.workflows[0]); setSelectedAction("delete")}}/>
                                    
                                    <Workflow   destinataires={l.workflows[1].destinataire} 
                                                display={selectedWorkflow === -1 || l.workflows.filter((w) => w.id_workflow === selectedWorkflow).length !== 0}
                                                libelle={l.workflows[1].workflow}
                                                color={l.workflows[1].case}
                                                date_change={l.workflows[1].date_change}
                                                date_echeance={l.workflows[1].date_echeance}
                                                selected={false}
                                                extended={false}
                                                id_logement={l.id_logement}
                                                validateur={l.workflows[1].validateur}
                                                nom_createur={l.workflows[1].createur}
                                                loading={loadingProgrammation === l.workflows[1].id_programmation}
                                                setSelectedIdLogement={setSelectedIdLogement}
                                                setSelectedIdProgrammation={setSelectedIdProgrammation}
                                                setDisplayLogement={setDisplayLogement}
                                                seen_by={seen_by}
                                                onBack={() => {setSelectedTache(undefined); setSelectedAction(undefined)}}
                                                onValidation={() => {setSelectedTache(selectedTache === undefined ? l.workflows[1] : l.workflows[1].id_workflow === selectedTache.id_workflow ? undefined : l.workflows[1]); setSelectedAction("next")}}
                                                onDeletion={() => {setSelectedTache(selectedTache === undefined ? l.workflows[1] : l.workflows[1].id_workflow === selectedTache.id_workflow ? undefined : l.workflows[1]); setSelectedAction("delete")}}/>
                                    
                                    
                                    <LinearGradient colors={['#FFFFFF50', '#FFF']} 
                                                    style={styles.filtreBlanc}/>
                                    <Text style={styles.titreWorkflow}>Toutes les tâches sont terminées</Text>
                                    <Pressable onPress={() => {
                                        setExpendLogement(l.id_logement)
                                        setExpandWorkflows("l"+l.id_logement+"d"+moment(l.workflows[0].date_echeance).tz("Europe/Paris").format("YYYY-MM-DD") != expandedWorkflows 
                                                                ? "l"+l.id_logement+"d"+moment(l.workflows[0].date_echeance).tz("Europe/Paris").format("YYYY-MM-DD") 
                                                                : undefined)
                                        }}>
                                        <Text style={styles.sousTitreWorkflow}>Voir les tâches</Text>
                                    </Pressable>
                                    
                                
                                </View>)
                            }
                            return (
                                <View key={`ListeWorkflows${res.date}${l.id_logement}`} style={styles.fullwidth}>
                                    <View style={styles.logementName}>
                                        <LogementAccueil    infos={l} 
                                                            setExpandWorkflows={() => {
                                                                setExpendLogement(-1);
                                                                setExpandWorkflows("l"+l.id_logement+"d"+moment(l.workflows[0].date_echeance).tz("Europe/Paris").format("YYYY-MM-DD") != expandedWorkflows 
                                                                ? "l"+l.id_logement+"d"+moment(l.workflows[0].date_echeance).tz("Europe/Paris").format("YYYY-MM-DD") 
                                                                : undefined)}}
                                                            expandedWorkflows={"l"+l.id_logement+"d"+moment(l.workflows[0].date_echeance).tz("Europe/Paris").format("YYYY-MM-DD") == expandedWorkflows}
                                                            filterWorkflow={selectedWorkflow}
                                                            filterEcheance={echeance}
                                                            setSelectedIdLogement={setSelectedIdLogement}
                                                            setDisplayLogement={setDisplayLogement}
                                                            seen_by={seen_by}
                                                            />

                                    </View>
                                
                        
                                {l.workflows.filter(w => w.id_workflow === selectedWorkflow || selectedWorkflow === -1).map((w) => {
                                    return (<View   key={`ListeWorkflows${res.date}${l.id_logement}${w.id_programmation}`}
                                                    style={styles.fullwidth} >
                                                <Workflow   destinataires={w.destinataire} 
                                                            display={selectedWorkflow === -1 || selectedWorkflow === w.id_workflow}
                                                            libelle={w.workflow}
                                                            color={w.case}
                                                            date_change={w.date_change}
                                                            date_echeance={w.date_echeance}
                                                            selected={false}
                                                            nom_createur={w.createur}
                                                            id_logement={l.id_logement}
                                                            id_programmation={w.id_programmation}
                                                            validateur={w.validateur}
                                                            extended={(extendedProgrammation.includes(w.id_programmation) && expandedWorkflows === undefined) 
                                                                || ("l"+w.id_logement+"d"+moment(w.date_echeance).tz("Europe/Paris").format("YYYY-MM-DD") == expandedWorkflows)}
                                                            extendable={true}
                                                            onExtend={() => {setExtendedProgrammation(extendedProgrammation.includes(w.id_programmation) ? [] : [w.id_programmation])}}
                                                            loading={loadingProgrammation === w.id_programmation}
                                                            onBack={() => {setSelectedTache(undefined); setSelectedAction(undefined)}}
                                                            onValidation={() => {setSelectedTache(selectedTache === undefined ? w : w.id_workflow === selectedTache.id_workflow ? undefined : w); setSelectedAction("next")}}
                                                            onDeletion={() => {setSelectedTache(selectedTache === undefined ? w : w.id_workflow === selectedTache.id_workflow ? undefined : w); setSelectedAction("delete")}}
                                                            setSelectedIdLogement={setSelectedIdLogement}
                                                            setSelectedIdProgrammation={setSelectedIdProgrammation}
                                                            setDisplayLogement={setDisplayLogement}
                                                            seen_by={seen_by}
                                                            />
                                                    
                                            </View>)
                                
                            })}
                            {(l.state === "green" || l.state === "deleted") && expendLogement === l.id_logement && echeance === "Aujourd'hui" ? 
                                    <Pressable onPress={() => {setExpendLogement(-1); setExpandWorkflows(undefined)}}>
                                        <Text style={styles.sousTitreWorkflow}>Cacher les tâches</Text>
                                    </Pressable>
                                :<></>}
                            </View>)
                            
                            

                        })}
              </View>
                );})
            }
            
            <View style={styles.clearSeparation} />
        </View>
        );
    }
}
    
const styles = StyleSheet.create({
    fullwidth: {
        width:"100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },  
    container: {
        width: "100%",
        backgroundColor: css_white,
        alignItems: 'center',
    },
    dayName: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginVertical: "1vh",
    },
    pointGreen: {
        backgroundColor: css_green
    },
    pointGrey: {
        backgroundColor: css_grey
    },
    pointOrange: {
        backgroundColor: css_orange
    },
    pointRed: {
        backgroundColor: css_red
    },
    pointBlue: {
        backgroundColor: css_bg_grey
    },
    point: {
        backgroundColor: css_white,
        width: "10px",
        height: "10px",
        borderRadius: "100%",
    },
    logementNameText: {
        fontWeight: 700,
        fontSize: "0.9rem",
        marginLeft: "1.5vw",
        zIndex: 9,
    },
    logementName: {
        width:"100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        marginVertical: "1.5vh",
        paddingLeft: "3%",
    },
    filtreBlanc: {
        width: "100%",    
        height: "120px",
        position: "absolute",
        zIndex: 0,
        bottom: 0
    },
    containerLogement: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    titreWorkflow: {
        marginTop:"-5vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        flexDirection: "row",
        flexWrap: "wrap",
        fontWeight:700,
        zIndex: 10
    },
    sousTitreWorkflow: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        flexDirection: "row",
        flexWrap: "wrap",
        fontSize: "0.7rem",
        zIndex: 10
    },
    clearSeparation: {
        backgroundColor: css_bg_grey,
        width: "75%",
        height: "1px",
        marginTop: "2vh"
        
    },
});
