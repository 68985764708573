import { useEffect, useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import attribution_service from '../../services/attribution.service';
import Titre from '../Titre';
import Button from '../Button'
import { css_bg_grey, css_green, css_grey, css_white } from '../../constants/Colors';
import SelecteurDroits from '../Elements/SelecteurDroits';
import AjoutAttributions from './AjoutAttributions';
import { screenWidth } from '../../constants/Const';

export default function Informations({idLogement, nestorOuProprio = false}) {
    const [loading, setLoading] = useState(true);
    const [pers, setPersonnel] = useState([]);
    const [add, setAdd] = useState(false)
    const [listOfUsers, setListOfUsers] = useState([])

    useEffect(() => {
        refresh()
    }, [idLogement])

    const refresh = () => {
        if(idLogement !== -1) {
            setLoading(true)
            attribution_service.getPersonnel(idLogement).then((personnel) => {
                setPersonnel(personnel)
                let tmp = [];
                for (let i = 0; i < personnel.length; i++) {
                    for(let j = 0; j < personnel[i].personnel.length; j++) {
                        let found = false;
                        tmp.forEach((t) => {
                            if (t.id_utilisateur === personnel[i].personnel[j].id_utilisateur)
                                found = true
                        })
                        if (!found)
                            tmp.push(personnel[i].personnel[j])
                    }
                }
                
                setListOfUsers(tmp)
                setLoading(false)
            })
        }
        
    }

    const update = ({id_utilisateur, id_workflow, id_logement}) => {
        setLoading(true)
        attribution_service.updateAttribution(id_logement, id_utilisateur, id_workflow)
        .then((res) => {
            refresh();
        })
    }

    useEffect(() => {
        refresh()
    }, [add])

    const styles = StyleSheet.create({
        container: {
            width:Math.min(screenWidth*0.9, 500*0.9),
            marginHorizontal:Math.min(screenWidth*0.05, 500*0.05),
            marginTop: "5vh",
        },
        role: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            paddingRight:"5%",
            paddingLeft: "5%",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: css_grey,
            borderRadius: "15px",
            marginVertical: "1vh"
        },
        
        button: {
            width: "30%"
        },
        libelle: {
            width: "30%"
        },
        utilisateur: {
            fontWeight: "400",
            fontSize: "1.1rem",
            marginVertical: "1vh",
            color: css_grey
        },
        droit: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginVertical: "1vh"
        }
    });
    

    return (!loading ?
        <>
            <View style={styles.container}>
                {nestorOuProprio && !add ? 
                  <View >
                    <Button title="+ Nouvelle attribution"
                            color={css_green}
                            fontWeight={600}
                            background={css_white} 
                          onPress={() => {setAdd(true)}}/>
                  </View> : <></>}
                {!add ? <><Titre size={1.2} texte={"Equipe du logement"} marginLeft={1} color={css_grey}/>
                { pers.map((p,key) => {
                return (p.libelle !== "Nestors" && p.libelle !== "Propriétaires" ?
                    <View style={styles.role} key={`Informations${p.id_workflow}${key}`}>
                        <Text style={styles.utilisateur}>Workflow : {p.libelle}</Text>
                        {p.personnel.map((d,keyd) => {
                            
                            return (
                            <View style={styles.droit} key={`InformationsPersonneld${p.id_workflow}${d.id_utilisateur}${keyd}`}>
                                <Text style={{color: css_grey}}>{d.utilisateur}</Text>
                                {nestorOuProprio ?
                                <SelecteurDroits    id_utilisateur={d.id_utilisateur}
                                                    id_workflow={p.id_workflow}
                                                    id_logement={idLogement}
                                                    onUpdate={update} />: <></>}
                            </View>)
                        })}              
                    </View>: <></>
                )
                }) }</>: <AjoutAttributions idLogement={idLogement}
                                            alreadyAttributed={listOfUsers}
                                            informations={pers}
                                            goBack={() => {setAdd(false)}} />}
            </View>
        </>
        
                
        : <></>
    );
}

