import { Pressable, StyleSheet, View, Text} from "react-native";
import { css_bg_grey, css_darkBlue, css_lightBlue } from "../../constants/Colors";
import { MaterialIcons, Feather, AntDesign } from '@expo/vector-icons';
import CalendrierPicker from "../Elements/CalendrierPicker";
import Titre from "../Titre";
import moment from 'moment-timezone';
import { useState } from "react";
import { screenWidth } from "../../constants/Const";

/**
 * Composant permettant d'afficher le filtre Utilisateurs / Logements.
 * @param {*} param0 
 * @returns 
 */
export default function DatesFilter({selectedBegin = moment.tz("Europe/Paris"), setSelectedBegin = null, selectedEnd = moment.tz("Europe/Paris"), setSelectedEnd = null, selectedMonth = "", setSelectedMonth= null}) {  
    
    const [todayIsInTheMonth, setTodayIsInTheMonth] = useState(false);
    const [displayCalendrier, setDisplayCalendrier] = useState(false);
    const [nextInSelection, setNextInSelection] = useState("begin");
    const [enableOnlyEndSelection, setEnableOnlyEndSelection] = useState(false);

    
    const executePreviousMonth = () => {
        // If you can go back it means that today cannot be in the displayed month
        setTodayIsInTheMonth(false);

        const begin = moment(selectedBegin).tz("Europe/Paris").clone();
        setSelectedBegin(begin.subtract(1, 'month'));
        const end = moment(selectedBegin).tz("Europe/Paris").subtract(1, 'month').endOf('month');
        setSelectedEnd(end);
        
        setSelectedMonth(begin.format("MMMM YYYY").toUpperCase());
    }

    const executeNextMonth = () => {
        const begin = moment(selectedBegin).tz("Europe/Paris");
        setSelectedBegin(begin.add(1, 'month'));
        
        if (begin.isSame(moment.tz("Europe/Paris"), "month")){
            setTodayIsInTheMonth(true);
            setSelectedEnd(moment.tz("Europe/Paris"));
        } else {
            const end = moment(selectedBegin).tz("Europe/Paris").add(1, 'month').endOf('month');
            setSelectedEnd(end);
        }
            
        setSelectedMonth(begin.format("MMMM YYYY").toUpperCase());

    }
    const clearPeriode = () => {
       setSelectedBegin(moment.tz("Europe/Paris").subtract(1,'month').startOf('month'));
       setSelectedEnd(moment.tz("Europe/Paris").subtract(1,'month').endOf('month'));
       setSelectedMonth(moment.tz("Europe/Paris").subtract(1,'month').startOf('month').format("MMMM YYYY").toUpperCase());
    }
    const changeBeginDate = (date) => {
        setSelectedBegin(date);
        setSelectedEnd(date);
        setNextInSelection("end");
        setSelectedMonth("PÉRIODE")
    }

    const changeEndDate = (date) => {
        setSelectedEnd(date);
        setDisplayCalendrier(false);
        setNextInSelection("begin");
        if(moment(selectedBegin).tz("Europe/Paris").isSame(moment(date).tz("Europe/Paris"), "month")) {
            if (moment(date).tz("Europe/Paris").isSame(moment(date).tz("Europe/Paris").endOf('month'), "day") 
                && moment(selectedBegin).tz("Europe/Paris").isSame(moment(date).tz("Europe/Paris").startOf('month'), "day")) {
                setSelectedMonth(moment(date).tz("Europe/Paris").startOf('month').format("MMMM YYYY").toUpperCase());
            } else {
                setSelectedMonth('PÉRIODE')
            }
        } else {
            setSelectedMonth("PÉRIODE")
        }
    }
    
    return (
        <>
           {/* MonthFilter */}
            <View style={styles.monthFilter}>
                {selectedMonth !== "PÉRIODE" ?
                    <Pressable  onPress={(e) => {e.preventDefault(); executePreviousMonth()}} 
                                style={[{width: "25%"}]}>
                        <Feather name="chevrons-left" style={styles.monthFilterText} />
                    </Pressable>
                : <></>}
                
                <Text style={[{width: "50%"}, styles.monthFilterText]}>{selectedMonth}</Text>
                
                {selectedMonth !== "PÉRIODE" && !todayIsInTheMonth ?
                    <Pressable  onPress={(e) => {e.preventDefault();executeNextMonth()}}
                                style={[{width: "25%"}]} >
                        <Feather name="chevrons-right" style={styles.monthFilterText} />
                    </Pressable>
                : <></>}
            </View>
            {selectedMonth === "PÉRIODE" ?
                <Pressable  onPress={(e) => {e.preventDefault(); clearPeriode()}}
                             >
                    <MaterialIcons name="clear" style={[styles.monthFilterText, styles.clearPeriodButton]} />
                </Pressable>
            : <></>}

            <View style={styles.clearSeparation} />

            {/* DatesFilter */}
            <View style={styles.monthPickerFilter}>
                <Pressable style={displayCalendrier && !enableOnlyEndSelection && nextInSelection === "begin" ? styles.iconToTextInSelection : styles.iconToText}
                            onPress={(e) => {e.preventDefault(); setEnableOnlyEndSelection(false); setDisplayCalendrier(true)}}>
                    <MaterialIcons name="date-range" style={[styles.monthFilterIcon, styles.monthFilterText]} />
                    <Text style={[styles.textUnderline,styles.datesFilterText]}>{moment(selectedBegin).format("DD/MM/YYYY")}</Text>
                </Pressable>
                
                <Text>-</Text>

                <Pressable style={displayCalendrier && (enableOnlyEndSelection || nextInSelection === "end") ? styles.iconToTextInSelection : styles.iconToText}
                            onPress={(e) => {e.preventDefault(); setEnableOnlyEndSelection(true); setDisplayCalendrier(true)}}>
                    <MaterialIcons name="date-range" style={[styles.monthFilterIcon, styles.monthFilterText]}/>
                    <Text style={[styles.textUnderline,styles.datesFilterText]}>{moment(selectedEnd).format("DD/MM/YYYY")}</Text>
                </Pressable>
            </View>

            {displayCalendrier ?
            <View>
                <Titre  texte={enableOnlyEndSelection || nextInSelection === "end" ? "Sélectionnez la date de fin souhaitée" : "Sélectionnez la date de début souhaitée"} 
                        size={1}
                        color={css_darkBlue}
                        fontWeight={700}
                        marginLeft={5}
                        marginBottom={2}
                        align='center'/>
                <CalendrierPicker   selectedBegin={selectedBegin}
                                    selectedEnd={selectedEnd}
                                    setSelectedBegin={changeBeginDate}
                                    setSelectedEnd={changeEndDate}
                                    enableOnlyEndSelection={enableOnlyEndSelection} />
                <Pressable  onPress={(e) => {e.preventDefault(); setDisplayCalendrier(false)}} 
                            style={styles.closeCalendarLink}><AntDesign name="closecircleo" size={12} color="black" /><Text style={styles.addMarginLeft}>Fermer le calendrier</Text></Pressable>
                        
            </View>
            : <></>}
            </>);

}
const styles = StyleSheet.create({ 
    monthFilter: {
        width: Math.min(screenWidth, 500),
        display: "flex",
        justifyContent: "flex-start",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingVertical: "1vh",
    },
    monthPickerFilter: {
        width: Math.min(screenWidth, 500),
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingVertical: "1vh",
    },
    monthFilterText: {
        fontWeight: 600,
        fontSize: "1.2rem",
        color: css_darkBlue,
        textAlign: 'center',
    },
    datePickerText: {
        fontWeight: 600,
        fontSize: "1.2rem",
        color: css_darkBlue,
        textAlign: 'center',
    },
    monthFilterIcon: {
        marginRight: "1vw",
        marginLeft: "1vw"
    },
    closeCalendarLink: {
        marginVertical: "2vh",
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    addMarginLeft: {
        marginLeft: "2vw"
    },
    textUnderline: {
        textDecorationLine: 'underline'
    },
    datesFilterText: {
        fontWeight: 400,
        fontSize: "1rem",
        color: css_darkBlue
    },
    iconToText: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        borderRadius: "15px",
        padding: "0.5%",
        marginHorizontal: "4vw"
    },
    iconToTextInSelection: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        backgroundColor: css_lightBlue,
        borderRadius: "15px",
        padding: "0.5%",
        marginHorizontal: "2vw",
        paddingHorizontal: "2vw"
    },
    clearSeparation: {
        backgroundColor: css_bg_grey,
        width: "75%",
        marginHorizontal: (100-75)/2+'%',
        height: "1px",
        marginTop: "2vh"
    },
    clearPeriodButton: {
        position: "absolute",
        marginLeft: "25vw",
        marginTop: "-4vh"
    }
});
