import { StyleSheet, View} from "react-native";
import { css_bg_grey, css_grey, css_white } from "../../constants/Colors";
import { MaterialIcons,MaterialCommunityIcons } from '@expo/vector-icons';
import { screenWidth } from "../../constants/Const";
/**
 * Composant permettant d'afficher un badge sur le logement avec l'état des checkin et checkout du logement.
 * @param {*} param0 
 * @returns 
 */
export default function BadgeVacant({isCheckin = false, isCheckout = false, occupied = false, where= "LogementAccueil"}) {
    return (
        <View style={where !== "LogementAccueil" ? [styles.chip, styles.chipLogement] : [styles.chip, styles.chipWorkflows]} >  
            {isCheckout ?
                <MaterialIcons  name='logout' 
                                color={css_grey}     
                                style={where !== "LogementAccueil" ? styles.iconWorkflows:styles.iconLogements} /> : <></>}
            {isCheckin ? 
                <MaterialIcons  name='login'
                                color={css_grey}     
                                style={where !== "LogementAccueil" ? styles.iconWorkflows:styles.iconLogements} /> : <></>}
            {!isCheckout && !isCheckin && occupied ? 
                <MaterialCommunityIcons name="checkbox-outline"
                                        color={css_grey}     
                                        style={where !== "LogementAccueil" ? styles.iconWorkflows:styles.iconLogements} />: <></>}
        </View>
    );
}

const styles = StyleSheet.create({
    chip: {
        borderRadius: "15px",
        paddingVertical: "1vh",
        paddingHorizontal: Math.min(screenWidth*0.02, 0.02*500),
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    chipWorkflows: {
        top: "1vh",
        width: "15vw",
        height: "5vh",
    },
    chipLogement: {
        width: "10vw",
        height: "4vh"
    },
    iconWorkflows: {
        fontSize: "1rem"
    },
    iconLogements: {
        fontSize: "1.1rem",
    }
});