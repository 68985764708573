import { StyleSheet, Pressable } from 'react-native';
import { css_grey, css_white } from '../../constants/Colors';
import { Ionicons } from '@expo/vector-icons';
import { screenWidth } from '../../constants/Const';

export default function AddBadge({setDisplayModale = () => {}, displayModale = false}) {
    

  const styles = StyleSheet.create({ 
    modale: {
        width: "50px",
        height: "50px",
        backgroundColor: css_white,
        borderRadius: "100%",
        display: "flex",
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        cursor: "pointer",
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: css_grey,
        position: "fixed",
        zIndex: 1,
        bottom: "5vh",
        right: screenWidth < 500 ? "5vw" : (screenWidth-500)/2 + 25,
    }
  });
  

  return ( 
        <Pressable  style={styles.modale} 
                    onPress={setDisplayModale}>
            {!displayModale ?
            <Ionicons name="add" size={24} color={css_grey} />
            :
            <Ionicons name="close-outline" size={24} color={css_grey} />}
        </Pressable>
  );
}


