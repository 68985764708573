import { View, StyleSheet } from "react-native";
import { css_bg_grey } from "../../constants/Colors";


/**
 * Composant servant de séparation
 * @param {*} param0 
 * @returns 
 */
export default function ClearSeparation(){
    return (
        <View style={styles.clearSeparation} />
    );
}
const styles = StyleSheet.create({ 
    clearSeparation: {
        backgroundColor: css_bg_grey,
        width: "75%",
        marginHorizontal: (100-75)/2+'%',
        height: "1px",
        marginTop: "2vh"
    },
});
