export const nestorLogo = require('../assets/merci-nestor-logo.jpeg')
export const bottomHome = require('../assets/bottom-home.svg')
export const fullLoading = require('../assets/loading/full.svg')
export const logementImage = require('../assets/logement.png')
export const decoNavbar = require('../assets/decorations/navbar.svg')
export const decoNavbar2 = require('../assets/decorations/navbar-deux.svg')
export const decoNavbar3 = require('../assets/decorations/navbar-trois.svg')
export const logoBanner = require('../assets/nestorbanner.jpeg')

// Google material icons
export const location = require('../assets/location.svg');
export const comment = require('../assets/comment.svg');
export const check = require('../assets/check.svg');
export const deleteIcon = require('../assets/delete.svg');
export const like = require('../assets/favorite.svg');
export const share = require('../assets/share.svg');
export const back = require('../assets/back.svg')
export const checkin = require('../assets/checkin.svg');
export const checkout = require('../assets/checkout.svg');
export const next = require('../assets/next.svg');
export const nonvacant = require('../assets/occupe.svg');
export const more = require('../assets/more.svg');
export const viewWorkflow = require('../assets/viewWorkflow.svg');
export const viewLogement = require('../assets/viewLogement.svg');
export const warning = require('../assets/warning.svg');
export const home = require('../assets/home.svg');
export const profil = require('../assets/profil.svg');