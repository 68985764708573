export const css_darkBlue = "#182D6D";
export const css_lightBlue = "#B2D6F9";
export const css_white = "#fff";
export const css_orange = "#EE934E";
export const css_bg_orange = "#D0352B54";
export const css_green = "#286720";
export const css_grey = "#939393";
export const css_bg_grey = "#EDEDED";
export const css_calendar_font_grey = "#C1BCBC";
export const css_bg_green = "#28672054";
export const css_red = "#F00020";
export const css_bg_red = "#A2414154";
export const css_black = "#171717";
export const css_bg_black = "#17171780";
export const css_jaune = "#EE934E";
export const css_white_modale = "#ffffff05";
export const css_bg_jaune = "#EE934E54";