import { getToken, removeToken, saveToken } from "../modules/localStorage.module";
import { post } from "../modules/ms-api.module";

const tryConnexion = async function({utilisateur = "", mot_de_passe = ""}) {
    const brut = await post({
        need_token: false,
        body: {
            utilisateur: `${utilisateur}`,
            mot_de_passe: `${mot_de_passe}`
        },
        uri: "/connexion"
    });
    const data = await brut.json();
    if (brut.status === 200) {
        saveToken(data.result)
        return {result:true};
    }
    else 
        return {result: false, message: data.result};
}

/**
 * Fonction permettant d'exécuter une inscription auprès de l'API.
 * @param {*} body 
 * @returns 
 */
const inscription = async function (body) {
    const brut = await  post({
        need_token: false,
        body: {
            utilisateur: `${body.utilisateur}`,
            mot_de_passe: `${body.mot_de_passe}`,
            mail: `${body.mail}`,
            phone: `${body.phone}`
        },
        uri: "/inscription"
    });
    if (brut.status === 200) return true;
    else return false;
}

const logout = async function() {
    removeToken();
}

const isConnected = function () {
    return getToken() != null;
}
const auth_service = {isConnected, logout, inscription, tryConnexion};
export default auth_service;