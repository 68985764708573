import { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { useNavigate, Link } from 'react-router-dom';
import packages from "../package.json"
import { css_darkBlue, css_grey, css_white } from "../constants/Colors";
import { screenWidth } from "../constants/Const";

export default function Footer({sidebarEnabled= false}) {
    const [version, setVersion] = useState("");
    const navigate = useNavigate();
    
    useEffect(() => {
        setVersion(packages.version)
    }, [])

    return (
        <View style={sidebarEnabled ? [styles.container, styles.sidebarEnabled] : styles.container}>
            <Text style={styles.textLogout}>Nestor <b>V.{version}</b></Text>
        </View> 
    );

}
const styles = StyleSheet.create({
    container: {
        backgroundColor: css_white,
        position: "absolute",
        width:Math.min(screenWidth, 500),
        bottom: "0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    sidebarEnabled: {
        marginLeft: screenWidth <=500 ? "-40vw" : 0
    }
});