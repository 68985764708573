import { get, put } from "../modules/ms-api.module";

const getTaches = async function() {
    const brut = await get({
        need_token: true,
        uri: "/etat/etats"
    });
    const data = await brut.json();
    if (brut.status === 200) return data.result;
    else return [];
}
const changerEtat = async function(finalObject) {
    const brut = await put({
        uri: "/etat/",
        need_token: true, 
        body: {
            id_programmation: `${finalObject.id_programmation}`,
            id_etape: `${finalObject.id_etape}`,
            id_workflow: `${finalObject.id_workflow}`,
            id_logement: `${finalObject.id_logement}`,
            id_validateur: `${finalObject.id_validateur}`
        }
    });
    return brut.status === 200;
}
const role_service = {changerEtat, getTaches};
export default role_service;