import { StyleSheet, View, Text, Pressable, ScrollView } from 'react-native';
import { css_black, css_darkBlue, css_green, css_white, css_white_modale } from '../../constants/Colors';
import Button from '../Button';
import { useEffect, useState } from 'react';
import programmation_service from '../../services/programmation.service';
import { CheckBox } from 'react-native-elements';
import { screenWidth } from '../../constants/Const';

export default function ExecuteNextStepModale({selectedUser = -1, setSelectedUser = () => {}, disappear = null, onValidation = null, id_programmation = -1}) {
  const [destinataires, setDestinataires] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(()=> {
    setLoading(true);
    if (id_programmation !== -1) {
      programmation_service.getDestinataires(id_programmation)
      .then((r) => {
        setDestinataires(r)
        if (r.length === 1) setSelectedUser(r[0].id_utilisateur)
        setLoading(false);
      })
    }
  }, [id_programmation])


  const styles = StyleSheet.create({ 
    bg: {
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        width: Math.min(screenWidth, 500),
        height: "100vh",
        zIndex: 1,
        top: 0,
        left: screenWidth<500 ? 0 : (screenWidth-500)/2,
        bottom: 0,
        backgroundColor: css_white_modale
    },
    modale: {
        width: Math.min(screenWidth*0.9, 0.9*500),
        backgroundColor: css_white,
        borderRadius: "15px",
        borderColor: css_black,
        borderWidth: "3px",
        paddingVertical: "4vh",
        display: "flex",
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        cursor: "default"
    },
    buttons: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        width: "80%"
    },
    liste: {
      width: "80%",
      marginVertical: "5%",
      maxHeight: "45vh"
    }
  });
  

  return (
      <Pressable    style={styles.bg}
                    onPress={disappear}>
        <Pressable  style={styles.modale} 
                    onPress={() => {}}>
            <Text>{destinataires.length > 1 ? "Qui a effectué la tâche ?" : "Êtes-vous sur de vouloir valider ?"}</Text>

            {loading ? <Text>Récupération des utilisateurs ...</Text>: <></>}

            <ScrollView horizontal={false}
                        style={styles.liste}>
              {destinataires.length > 1 ?
              destinataires.map((d) => {
                return (
                  <CheckBox title={d.utilisateur}
                            key={`NextStepModale${d.id_utilisateur}`}
                            checkedColor={css_darkBlue}
                            checked={selectedUser === d.id_utilisateur}
                            onPress={(e) => {e.preventDefault(); setSelectedUser(d.id_utilisateur);}}/>
                );
              }):<></>}  
            </ScrollView>

            <View style={styles.buttons}>
                <Button onPress={disappear} 
                        title={"Annuler"}
                        color={css_black}
                        borderColor={css_black}
                        size="medium"/>
                {!loading && selectedUser !== -1 ?
                  <Button onPress={onValidation} 
                        title={"Valider"}
                        background={css_green}
                        borderColor={css_green}
                        size="medium"
                        color={css_white}/>
                  : <></>}
            </View>
        </Pressable>


      </Pressable>
  );
}


