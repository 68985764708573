import { View, Text, Pressable, StyleSheet } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { css_green, css_grey } from "../../constants/Colors";
import programmation_service from '../../services/programmation.service';
import { useState } from "react";
import moment from "moment-timezone";
import { screenWidth } from "../../constants/Const";

export default function RecapView({ recapitulatifListe = [],
                                    enableUser = true, 
                                    enableLogement = true, 
                                    selectedBegin = moment.tz("Europe/Paris"), 
                                    selectedEnd = moment.tz("Europe/Paris"), 
                                    selectedWorkflows = [], 
                                    selectedUsers = [],
                                    listOfAllUsers = [], 
                                    listOfAllHomes = [],
                                    selectedHomes= [],
                                    seen_by=undefined}) {

    const [dwLoading, setDwLoading] = useState(false);
    const launchDownload = (e) => {
        e.preventDefault();
        setDwLoading(true);
        programmation_service.downloadRecapitulatifAsPDF( selectedBegin, 
                                    selectedEnd, 
                                    selectedWorkflows, 
                                    selectedUsers.length === listOfAllUsers.length ? undefined : selectedUsers, 
                                    selectedHomes.length === listOfAllHomes.length ? undefined : selectedHomes, 
                                    enableLogement, 
                                    enableUser,
                                    seen_by)
            .then((r) => {
                setDwLoading(false);
            });
    }

    return (
    <View>
        <Pressable  style={[styles.downloadButton, styles.downloadPDF]}
                    onPress={(e) => {launchDownload(e)}}>
            { dwLoading ? <Text style={styles.downloadButtonText}>Téléchargement en cours ...</Text> : <Text style={styles.downloadButtonText}>Télécharger</Text>}
            { dwLoading ? <MaterialIcons name="autorenew" size={14} color={css_grey} /> : <MaterialIcons name="file-download" size={14} color={css_grey} />}
        </Pressable>

        <View style={styles.ctn}>
                {recapitulatifListe.map((w, key) => {
                        return ( selectedWorkflows === -1 || selectedWorkflows === w.id_workflow ?
                            <View   style={!enableUser & !enableLogement ? [styles.flex] : []}
                                    key={`RecapWorkflow${w.id_workflow}${key}`}>
                                <Text>{w.workflow}</Text>
                                {/* Sans logement Sans utilisateur */}
                                {!enableUser && !enableLogement ? <Text style={[styles.count]}>[{w.count}]</Text>:<></>}

                                {/* Avec logement Sans utilisateur */}
                                {!enableUser && enableLogement ? w.logements.map((l) => {
                                    if (selectedUsers.length === 0 || l.utilisateurs.filter((u) => selectedUsers.includes(u.id_utilisateur)).length !== 0) {
                                        return (
                                            <View style={[styles.flex, {paddingLeft: Math.min(0.15*screenWidth, 0.15*500)}]} key={`RecapWorkflowLogement${l.id_logement}`}>
                                                {(selectedHomes.length === 0 || selectedHomes.includes(l.id_logement)) ? <Text>{l.libelle_logement}</Text>:<></>}
                                                {(selectedHomes.length === 0 || selectedHomes.includes(l.id_logement)) ? <Text style={[styles.count]}>[{l.count}]</Text>:<></>}
    
                                            </View>
                                    );}
                                }):<></>}

                                {/* Sans logement Avec utilisateur */} 
                                { enableUser && !enableLogement ? 
                                    w.utilisateurs.map((u) => {
                                        return ( 
                                            <View style={[styles.flex, {paddingLeft: Math.min(0.15*screenWidth, 0.15*500)}]} key={`RecapWorkflowLogementUtilisateurs${u.id_utilisateur}`}>
                                                <Text>{u.utilisateur}</Text>
                                                <Text style={[styles.count]}>[{u.count}]</Text>
                                            </View> );
                                    })
                                :<></>}

                                {/* Avec logement Avec utilisateur */}
                                { enableUser && enableLogement ?
                                    w.logements.map((l) => {
                                        return (
                                            <>
                                                <View   style={styles.alinea}
                                                    key={`RecapLogements${l.id_logement}`}>
                                                    {(selectedHomes.length === 0 || selectedHomes.includes(l.id_logement)) ? <Text>{l.libelle_logement}</Text>:<></>}
                                                </View>
                                                {
                                                    l.utilisateurs.map((u) => {
                                                        return ( 
                                                            <View   key={`RecapUtilisateurs${u.id_utilisateur}`}>
                                                                <View style={[styles.flex, {paddingLeft: Math.min(0.3*screenWidth, 0.3*500)}]}>
                                                                    {enableUser ? <Text>{u.utilisateur}</Text>:<></>}
                                                                    {enableUser ? <Text style={[styles.count]}>[{u.count}]</Text>:<></>}
                                                                </View>
                                                            </View>
                                                       );
                                                    })
                                                }
                                            </>
                                        );
                                    })
                                    : <></>}
                            </View>
                        :<></>);
                })}
            </View>
    </View>
    );
}

const styles = StyleSheet.create({ 
    downloadButton: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: "center",
        alignItems: "center",
        justifyContent: "flex-end",
        marginVertical: "1vh",
        paddingRight: "2vw"
    },
    downloadButtonText: {
        fontSize: 12,
        color: css_grey,
        marginRight: "1vw",
        fontStyle: "italic"
    },
    downloadPDF: {
        paddingRight: Math.min(0.13*screenWidth, 0.13*500)
    },
    alinea: {
        marginLeft: Math.min(0.15*screenWidth, 0.15*500)
    },
    flex: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%"
    },
    count: {
        fontWeight: "700",
        color: css_green,
    },
    ctn: {
        width: Math.min(0.7*screenWidth, 0.7*500),
        marginHorizontal: Math.min(0.15*screenWidth, 0.15*500)
    },
});