import { StyleSheet, Pressable,View,Text } from "react-native";
import { css_bg_grey, css_darkBlue, css_green, css_grey, css_orange, css_red, css_white } from "../../constants/Colors";
import TachesActuelles from "../Logement/TachesActuelles";
import { useEffect } from "react";
import { useState } from "react";
import programmation_service from "../../services/programmation.service";
import { screenWidth } from "../../constants/Const";

/**
 * Composant servant d'élément de filtre d'échéance
 * @param {*} param0 
 * @returns 
 */
export default function FiltreEcheance({loading = true, echeance = "none", setEcheance = null, setSelectedWorkflow = null, selectedWorkflow=-1, enableWorkflowsFilter=false, refreshOnMod = null, seen_by = undefined}){
    const [t, setTaches] = useState([]);
    
    const [countToday, setCountToday] = useState(0);
    const [countTerminated, setCountTerminated] = useState(0);
    const [countTomorrow, setCountTomorrow] = useState(0);
    const [countLater, setCountLater] = useState(0);

    const [workflowsToday, setWorkflowsToday] = useState([]);
    const [workflowsTomorrow, setWorkflowsTomorrow] = useState([]);
    const [workflowsLater, setWorkflowsLater] = useState([]);

    const [l, setLoading] = useState(true);

    const fetch = async () => {
        setLoading(true);
        programmation_service.getFiltreEcheance(seen_by).then((r) => {
            setCountToday(r["Aujourd'hui"].count);
            setCountTerminated(r["Aujourd'hui"].terminated);
            setCountTomorrow(r["Demain"].count);
            setCountLater(r["Semaine"].count);
            setWorkflowsToday(r["Aujourd'hui"].workflows);
            setWorkflowsTomorrow(r["Demain"].workflows);
            setWorkflowsLater(r["Semaine"].workflows);
            refresh();
            setLoading(false);
        })
    }

    useEffect(() => {fetch();}, []);

    useEffect(()=> {refresh();}, [echeance, selectedWorkflow,refreshOnMod, workflowsToday, workflowsLater, workflowsTomorrow]);

    useEffect(() => {setLoading(loading);}, [loading]);

    const refresh = () => {
        if (echeance === "Aujourd'hui") {
            setTaches(workflowsToday);
            if (workflowsToday.filter(tache => tache.id_workflow===selectedWorkflow).length === 0 && selectedWorkflow !== -1) 
                setSelectedWorkflow(-1);
        }
        if (echeance === "Demain") {
            setTaches(workflowsTomorrow);
            if (workflowsTomorrow.filter(tache => tache.id_workflow===selectedWorkflow).length === 0 && selectedWorkflow !== -1) 
                setSelectedWorkflow(-1);
        }
        if (echeance === "Semaine") {
            setTaches(workflowsLater);
            if (workflowsLater.filter(tache => tache.id_workflow===selectedWorkflow).length === 0 && selectedWorkflow !== -1) 
                setSelectedWorkflow(-1);
        }
        
    }

    const styles = StyleSheet.create({
        container: {
            width: Math.min(screenWidth, 500),
            marginTop: "2vh",
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            flexDirection: "row"
          },
        echeanceSelected: {
            backgroundColor: css_darkBlue,
            padding: "1%",
            borderRadius: "5px",
            marginHorizontal: "5%",
            width:"25%"
        },
        echeanceNotSelected: {
            backgroundColor: css_white,
            padding: "1%",
            borderRadius: "5px",
            marginHorizontal: "5%",
            width:"25%"
        },
        notificationText: {
            fontWeight: 700,
            color: css_white
        },
        redNotification: {
            width: "20px",
            height: "20px",
            backgroundColor: css_red,
            borderRadius: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: "-1.5vh",
            right: -Math.min(screenWidth*0.01, 500*0.01)
        },
        greenNotification: {
            width: "20px",
            height: "20px",
            backgroundColor: css_green,
            borderRadius: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: "-1.5vh",
            right: -Math.min(screenWidth*0.01, 500*0.01)
        },
        orangeNotification: {
            width: "20px",
            height: "20px",
            backgroundColor: css_orange,
            borderRadius: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: "-1.5vh",
            right: -Math.min(screenWidth*0.01, 500*0.01)
        },
        filterTextSelected: {
            textAlign: "center",
            fontWeight: 600,
            color: css_white 
        },
        filterTextNotSelected: {
            textAlign: "center",
            fontWeight: 400,
            color: css_darkBlue
        },
        button: {
            display: "flex",
            justifyContent:"center",
            alignContent: "center"
        },
        greyNotification: {
            width: "20px",
            height: "20px",
            backgroundColor: css_grey,
            borderRadius: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: "-1.5vh",
            right: -Math.min(screenWidth*0.01, 500*0.01)
        },
        clearSeparation: {
            backgroundColor: css_bg_grey,
            width: "75%",
            height: "1px",
            marginTop: "2vh"
        },
        filterWorkflows: {
            width: "100%" 
        },
    });
    
    if (l) {
        return (
            <></>
        );
    } else {
        return (
            <>
                <View style={styles.container}>
                    <View style={echeance === "Aujourd'hui" ? styles.echeanceSelected : styles.echeanceNotSelected}>
                        
                        <Pressable onPress={() => {setEcheance("Aujourd'hui")}} style={styles.button}>
                            <Text style={echeance === "Aujourd'hui" ? styles.filterTextSelected : styles.filterTextNotSelected}>Aujourd'hui</Text>  
                        </Pressable>

                        {countToday !== 0 ? 
                        <View style={styles.redNotification}>
                            <Text style={styles.notificationText}>{countToday}</Text>
                        </View>
                        : countTerminated !== 0 ?
                        <View style={styles.greenNotification}>
                            <Text style={styles.notificationText}>{countTerminated}</Text>
                        </View>
                        :
                        <></> }
                    
                    </View>

                    <View style={echeance === "Demain" ? styles.echeanceSelected : styles.echeanceNotSelected}>
                        
                        <Pressable onPress={() => {setEcheance("Demain")}} style={styles.button}>
                            <Text style={echeance === "Demain" ? styles.filterTextSelected : styles.filterTextNotSelected}>Demain</Text>
                        </Pressable>
                        
                        {countTomorrow !== 0 ?
                        <View style={styles.orangeNotification}>
                            <Text style={styles.notificationText}>{countTomorrow}</Text>
                        </View>:<></>}
                    
                    </View>
                
                    <View style={echeance === "Semaine" ? styles.echeanceSelected : styles.echeanceNotSelected}>
                        
                        <Pressable onPress={() => {setEcheance("Semaine")}} style={styles.button}>
                            <Text style={echeance === "Semaine" ? styles.filterTextSelected : styles.filterTextNotSelected}>Semaine</Text>
                        </Pressable>
                        
                        {countLater !== 0 ?
                        <View style={styles.greyNotification}>
                            <Text style={styles.notificationText}>{countLater}</Text>
                        </View>:<></>}
                    </View>
                </View>

                {t.length !== 0 ? <View style={styles.clearSeparation} />:<></>}
        
                {enableWorkflowsFilter ?
                <>
                    <View style={styles.filterWorkflows}>
                        <TachesActuelles    onSelection={setSelectedWorkflow}
                                            taches={t}
                                            page="accueil"
                                            filter={selectedWorkflow} />
                    </View>
        
                    {t.length !== 0 ? <View style={styles.clearSeparation} />:<></>}
                </>:<></>}
            </>
        );
    }
}