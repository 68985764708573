import { StyleSheet, Pressable,View } from "react-native";
import { css_darkBlue, css_white } from "../../constants/Colors";
import { MaterialIcons, FontAwesome, Ionicons } from '@expo/vector-icons';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { screenWidth } from "../../constants/Const";

/**
 * Composant servant d'élément de filtre de vue
 * @param {*} param0 
 * @returns 
 */
export default function FiltreVue({ loading = true, sidebarEnabled= false, setEnableSidebar= null, iconSideBar = true, seen_by = undefined, setDisplayRecap = () => true}){

    const navigate = useNavigate();
    const styles = StyleSheet.create({
        filterContainer: {
            width: Math.min(screenWidth*0.9, 500*0.9),
            marginVertical: "1vh",
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "-5vh"
        },
        viewsContainer : {
            display: "flex",
            flexWrap: "nowrap",
            alignContent: "space-around",
            alignItems: "center",
            justifyContent: "space-around",
            flexDirection: "row",
            width: "90px",
        },
        bgNotSelected: {
            backgroundColor: css_darkBlue,
            padding: "3%"
        },
        bgSelected: {
            borderRadius: "5px",
            backgroundColor: css_darkBlue,
            borderColor: css_white,
            borderWidth: "1px",
            padding: "3%"
        },
        iconView: {
            fontSize: "1.5rem",
            color: css_white
        },
        separation: {
            width: "2px",
            height: "20px",
            backgroundColor: css_white
        },
        viewsContainerAllLogements : {
            display: "flex",
            flexWrap: "nowrap",
            alignContent: "space-around",
            alignItems: "center",
            justifyContent: "space-around",
            flexDirection: "row",
            width: "30px",
        },

    });
    
    if (loading) {
        return(<></>);
    } 
    else {
        return (
            <View style={styles.filterContainer}>
                <View style={styles.viewsContainer}>
                    <Pressable style={ styles.bgSelected} >
                        <MaterialIcons name="format-list-bulleted" style={styles.iconView} />
                    </Pressable>
                    <View style={styles.separation} ></View>
                    {seen_by === undefined ?
                        <Pressable onPress={(e) => {e.preventDefault();navigate(`/recap`)}} style={styles.bgNotSelected}>
                            <FontAwesome name="bar-chart-o" style={styles.iconView} />
                        </Pressable>
                    :   <Pressable onPress={(e) => {e.preventDefault();setDisplayRecap(true)}} style={styles.bgNotSelected}>
                            <FontAwesome name="bar-chart-o" style={styles.iconView} />
                        </Pressable>}
                </View>
                {iconSideBar ?
                <View style={styles.viewsContainerAllLogements}>
                    <Pressable onPress={() => {setEnableSidebar(!sidebarEnabled)}} style={styles.bgNotSelected}>
                        <Ionicons name="person" style={styles.iconView} />
                    </Pressable>
                 </View>:<></>}

                
          </View>
        );
       
    }

}