import { View, Text, Pressable, StyleSheet } from "react-native";
import moment from 'moment-timezone';
import { MaterialIcons } from "@expo/vector-icons";
import { css_darkBlue, css_grey } from "../../constants/Colors";
import programmation_service from '../../services/programmation.service';
import { useState } from "react";
import { screenWidth } from "../../constants/Const";

export default function RecapTable({    recapitulatifTableau = [], 
                                        selectedUsers = [], 
                                        selectedHomes = [], 
                                        selectedWorkflows = [], 
                                        selectedBegin = moment.tz("Europe/Paris"), 
                                        selectedEnd = moment.tz("Europe/Paris"), 
                                        listOfAllHomes = [], 
                                        listOfAllUsers = [],
                                        seen_by = undefined}) {
    
    const [dwLoading, setDwLoading] = useState(false);

    const launchDownload = (e) => {
        e.preventDefault();
        setDwLoading(true);
        programmation_service.downloadRecapitulatifAsCSV( selectedBegin, 
                                    selectedEnd, 
                                    selectedWorkflows, 
                                    selectedUsers.length === listOfAllUsers.length ? undefined : selectedUsers, 
                                    selectedHomes.length === listOfAllHomes.length ? undefined : selectedHomes,
                                    seen_by)
            .then((r) => {
                setDwLoading(false);
            });
    }

    return (
        <>
            <View style={styles.dateDownloadContainerTableau}>
                <Text style={styles.dateTableau}>{`Du ${moment(selectedBegin).tz("Europe/Paris").format("DD/MM/YY")} au ${moment(selectedEnd).tz("Europe/Paris").format("DD/MM/YY")}`}</Text>
                <Pressable style={styles.downloadButton}
                            onPress={(e) => {launchDownload(e)}}>
                    { dwLoading ? <Text style={styles.downloadButtonText}>Téléchargement en cours ...</Text> : <Text style={styles.downloadButtonText}>Télécharger</Text>}
                    { dwLoading ? <MaterialIcons name="autorenew" size={14} color={css_grey} /> : <MaterialIcons name="file-download" size={14} color={css_grey} />}
                </Pressable>
            </View>
            <View style={styles.table}>
                <View style={styles.row}>
                    <Text style={styles.cell}>Date</Text>
                    <Text style={styles.cell}>Tâches</Text>
                    <Text style={styles.cell}>Logement</Text>
                    <Text style={styles.cell}>Intervenant</Text>
                </View>
                {recapitulatifTableau.map((w, key) => {
                    if ((selectedUsers.length === 0 || selectedUsers.includes(w.modified_by)) && (selectedHomes.length === 0 || selectedHomes.includes(w.id_logement)) && (selectedWorkflows === -1 || w.id_workflow === selectedWorkflows)) {
                        return (
                            selectedWorkflows === -1 || w.id_workflow === selectedWorkflows ?
                            <View style={styles.row} key={`RecapTableauWorkflow${key}`}>
                                <Text style={styles.cell}>{moment(w.date_echeance).tz("Europe/Paris").format("DD/MM/YY")}</Text>
                                <Text style={styles.cell}>{w.workflow}</Text>
                                <Text style={styles.cell}>{w.libelle_logement}</Text>
                                <Text style={styles.cell}>{w.utilisateur}</Text>
                            </View>
                        :<></>);
                    }
                })}
            </View>
        </>
    );
}

const styles = StyleSheet.create({ 
    table: {
        width: Math.min(0.95*screenWidth, 0.95*500),
        borderWidth: 0,
        display: "flex",
        marginLeft: "2.5%"
    },
    row: {
        flexDirection: 'row',
        width: Math.min(0.95*screenWidth, 0.95*500)
    },
    cell: {
        padding: 5,
        borderWidth: 1,
        borderColor: '#000',
        width: Math.min((0.95/4)*screenWidth, (0.95/4)*500),
        fontSize: "0.8rem",
        display: "flex",
        flexWrap: "wrap",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center"
    },
    downloadButton: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: "center",
        alignItems: "center",
        justifyContent: "flex-end",
        marginVertical: "1vh",
        paddingRight: "2vw"
    },
    downloadButtonText: {
        fontSize: 12,
        color: css_grey,
        marginRight: "1vw",
        fontStyle: "italic"
    },
    dateDownloadContainerTableau: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center"
    },
    dateTableau: {
        color: css_darkBlue,
        marginLeft: "2.5%"
    },
});