import { StyleSheet, View, Pressable,ScrollView } from 'react-native';
import Titre from '../Titre';
import { css_bg_grey, css_grey} from '../../constants/Colors';
import TacheChip from '../Elements/TacheChip';
import { useEffect } from 'react';
import { useState } from 'react';
import { sortTaches } from '../../constants/Sort';

export default function TachesActuelles({onSelection = null, taches = [], filter = -1, page = "logement"}) {
  const [t, setTaches] = useState([]);
  
  useEffect(() => {
      const res = [];
      for (let i = 0; i < taches.length; i++) {
        if(res.filter(t => t.id_workflow === taches[i].id_workflow).length < 1)
          res.push(taches[i])
      }
      setTaches(res.sort(sortTaches))
    
  }, [taches])

  const changeSelection = (id_workflow) => {onSelection(id_workflow === filter ? -1 : id_workflow);}

  const styles = StyleSheet.create({ 
    etats: {
      paddingLeft: page === "logement" ? "5%" : "2%",
      borderColor: css_bg_grey
    },
    lastTaches: {
      display: "flex",
      alignContent: "center",
      justifyContent: "flex-start",
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      paddingTop: "3vh",
    },
    check: {
      width: "30vw",
      height: "30vw",
      margin: "5vw"
    },
    checkContainer: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center"
    },
  });
  
  if (t.length === 0 ) return (<></>)
  return (
      <View style={styles.etats}>
        
        <View style={styles.lastTaches} >
          {t.length === 0 ? <Titre texte="Aucune tâche prévue."
                                        color={css_grey}/>: <></>}
          <ScrollView horizontal={true}  contentContainerStyle={{ flexGrow: 1,justifyContent: page === "logement" ? 'flex-start' : 'center' }}>
          {t.length !== 0 ? t.map((ta) => {
            return(
              <Pressable onPress={() => changeSelection(ta.id_workflow)} key={`TachesActuellesTaches${ta.id_workflow}`}>
                <TacheChip  libelle={ta.workflow} 
                            color={page === "recap" ? "blue" : ta.case}
                            selected={filter === ta.id_workflow}
                            shadowed={filter !== -1 && filter !== ta.id_workflow} />
                
            </Pressable>)
          }): <></>}
          </ScrollView>
        </View>

      </View>
  );
}


