import { StyleSheet, View, Text, Pressable } from 'react-native';
import { css_black, css_darkBlue, css_red, css_white, css_white_modale } from '../../constants/Colors';
import Button from '../Button';
import { CheckBox } from 'react-native-elements';
import { screenWidth } from '../../constants/Const';

export default function HomesRecapModale({disappear = null, listOfAllHomes = [], selectedHomes = [], setSelectedHomes = null}) {
  

  const change = (id_logement) => {
    if (selectedHomes.includes(id_logement)) {
      setSelectedHomes(prev => [...prev.filter((u) => u !== id_logement)]);
    } else {
      setSelectedHomes(prev => [...prev, id_logement]);
    }
  }

  const clear = () => {
    const clearing = [];
    for (let i = 0; i < listOfAllHomes.length; i++) {
      clearing.push(listOfAllHomes[i].id_logement);
    }
    setSelectedHomes(clearing);
  }

  const styles = StyleSheet.create({ 
    bg: {
      position: "fixed",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      width: Math.min(screenWidth, 500),
      height: "100vh",
      zIndex: 1,
      top: 0,
      left: screenWidth<500 ? 0 : (screenWidth-500)/2,
      bottom: 0,
      backgroundColor: css_white_modale
  },
  modale: {
      width: Math.min(0.9*screenWidth, 0.9*500),
      backgroundColor: css_white,
      borderRadius: "15px",
      borderColor: css_black,
      borderWidth: "3px",
      paddingVertical: "4vh",
      display: "flex",
      justifyContent: "space-around",
      alignContent: "center",
      alignItems: "center",
      cursor: "default"
  },
    reset: {
      marginVertical: "2vh",
      textDecorationStyle: "solid",
      textDecorationLine: "underline"
    },
    buttons: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "80%",
        marginVertical:"2vh"
    }
  });
  

  return (
      <Pressable    style={styles.bg}
                    onPress={disappear}>
        <Pressable  style={styles.modale} 
                    onPress={() => {}}>
            <Text>Liste des logements disponibles</Text>


            <View style={styles.buttons}>
              {listOfAllHomes.map((u) => {
                return (
                  <CheckBox title={u.libelle_logement}
                            key={`HomesRecapModale${u.id_logement}`}
                            checkedColor={css_darkBlue}
                            checked={selectedHomes.includes(u.id_logement)}
                            onPress={(e) => {e.preventDefault(); change(u.id_logement);}}
                  />);
              })}
               {listOfAllHomes.length !== selectedHomes.length ?           
                <Pressable  style={styles.reset}
                            onPress={() => {clear()}}>Réinitialiser les choix</Pressable>:<></>}
                <Button onPress={disappear} 
                        title={"Fermer"}
                        color={css_black}
                        borderColor={css_black}
                        size="medium"/>
                
            </View>
        </Pressable>


      </Pressable>
  );
}


