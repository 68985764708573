import { Pressable, StyleSheet, View} from "react-native";
import { css_darkBlue } from "../../constants/Colors";
import { MaterialIcons,MaterialCommunityIcons, Feather } from '@expo/vector-icons';
import { CheckBox } from "react-native-elements";
/**
 * Composant permettant d'afficher le filtre Utilisateurs / Logements.
 * @param {*} param0 
 * @returns 
 */
export default function UsersHomesFilter({enableLogement = false, setEnableLogement = null, enableUser = false, setEnableUser = null, selectedUsers = [], listOfAllUsers = [], selectedHomes = [], listOfAllHomes = [], setDisplayUsersModale = null, setDisplayHomesModale = null}) {
    return (
        <View style={styles.container} >
            <View style={styles.logementctn}>
                <CheckBox   checked={enableLogement}
                            style={styles.checkbox}
                            checkedColor={css_darkBlue}
                            wrapperStyle={{margin:0}}
                            textStyle={{margin: 0}}
                            containerStyle={{backgroundColor: 'transparent', borderWidth:0,padding:0, margin: 0,marginLeft:0, marginRight:0}}
                            onPress={(e) => {e.preventDefault(); setEnableLogement(!enableLogement)}}/>
                <MaterialIcons name="home-filled" size={24} color={css_darkBlue} />
                <Pressable  onPress={(e) => {e.preventDefault(); setDisplayHomesModale(true);}}>
                    {selectedHomes.length === listOfAllHomes.length ?
                        <MaterialCommunityIcons  name="filter-off" size={24} color={css_darkBlue} />
                    :
                        <MaterialCommunityIcons name="filter" size={24} color={css_darkBlue} />}
                </Pressable>
            </View>
            <View style={styles.userctn}>
                <CheckBox   checked={enableUser}
                            style={styles.checkbox}
                            checkedColor={css_darkBlue}
                            wrapperStyle={{margin:0}}
                            textStyle={{margin: 0}}
                            containerStyle={{backgroundColor: 'transparent', borderWidth:0,padding:0, margin: 0,marginLeft:0, marginRight:0}}
                            onPress={(e) => {e.preventDefault(); setEnableUser(!enableUser)}}/>
                <Feather name="user" size={24} color={css_darkBlue} />
                <Pressable  onPress={(e) => {e.preventDefault(); setDisplayUsersModale(true);}}>
                    {selectedUsers.length === listOfAllUsers.length ?
                        <MaterialCommunityIcons  name="filter-off" size={24} color={css_darkBlue} />
                    :
                        <MaterialCommunityIcons name="filter" size={24} color={css_darkBlue} />}
                </Pressable>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center"
    },
    logementctn: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    userctn: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    checkbox: {
        padding: 0,
        margin:0
    }
});