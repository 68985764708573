import { Pressable, StyleSheet,Text, View } from "react-native";
import { css_bg_black, css_bg_grey, css_black, css_calendar_font_grey, css_darkBlue, css_green, css_grey, css_jaune, css_orange, css_red, css_white, css_white_modale } from "../../constants/Colors";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment-timezone";

/**
 * Composant servant d'élément de date dans le Calendrier
 * @param {*} param0 
 * @returns 
 */
export default function JourDuCalendrier({day=0,date=moment.tz("Europe/Paris"), today=false, past=false, checkin=false, checkout=false, occupied=false, taches = [], isSelectable = false, onSelection = null, selected= false, shadowed = false, isPicker=false}){
    
const styles = StyleSheet.create({
    container: {
        width: "calc(100% / 7)",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    day: {
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        cursor: "default",
        color: selected ? css_white : css_calendar_font_grey
    },
    isToday: {
        borderWidth: "2px",
        borderColor: shadowed ? css_bg_black : css_black,
        width:"30px",
        height: "30px",
        fontWeight: "600",
        color: taches.filter(t => moment(t.date_echeance).tz("Europe/Paris").isSame(moment.tz("Europe/Paris"), "day")).length > 0 || selected ? css_white : css_black
    },
    isPast: {
        textDecorationLine: "line-through",
        opacity: 0.8
    },
    isOccupied: {
        position: "absolute",
        width:"100%",
        height: "30px",
        backgroundColor: isPicker ? css_darkBlue : css_bg_grey,
        zIndex: "-1"
    },
    isCheckIn: {
        position: "absolute",
        right: "0",
        width:"70%",
        height: "30px",
        backgroundColor: isPicker ? css_darkBlue : css_bg_grey,
        borderTopLeftRadius: "15px",
        borderBottomLeftRadius: "15px",
        zIndex: "-1"
    },
    isCheckOut: {
        position: "absolute",
        left: "0",
        width:"70%",
        height: "30px",
        backgroundColor: isPicker ? css_darkBlue : css_bg_grey,
        borderTopRightRadius: "15px",
        borderBottomRightRadius: "15px",
        zIndex: "-1"
    },
    isOrange: {
        position: "absolute",
        borderRadius: selected ? "5px" : "100%",
        color: css_white,
        width:"30px",
        height: "30px",
        zIndex: "-1",
        backgroundColor: css_orange
    },
    isRed: {
        position: "absolute",
        borderRadius: selected ? "5px" : "100%",
        color: css_white,
        width:"30px",
        height: "30px",
        zIndex: "-1",
        backgroundColor: css_red
    },
    isGrey: {
        position: "absolute",
        borderRadius: selected ? "5px" : "100%",
        color: css_white,
        width:"30px",
        height: "30px",
        zIndex: "-1",
        backgroundColor: css_grey
    },
    isGreen: {
        position: "absolute",
        borderRadius: selected ? "5px" : "100%",
        color: css_white,
        width:"30px",
        height: "30px",
        zIndex: "-1",
        backgroundColor: css_green
    },
    isJaune: {
        position: "absolute",
        borderRadius: selected ? "5px" : "100%",
        color: css_white,
        width:"30px",
        height: "30px",
        zIndex: "-1",
        backgroundColor: css_jaune
    },
    isBlack: {
        position: "absolute",
        borderRadius: selected ? "5px" : "100%",
        color: css_white,
        width:"30px",
        height: "30px",
        zIndex: "-1",
        backgroundColor: css_black
    },
    shadowed: {
        backgroundColor: css_white,
        width:"100%",
        height: "30px",
        zIndex: "-1",
        position: "absolute",
        opacity: 0.5
    }
});
    
    const [stylesArrayText, setStylesArrayText] = useState([styles.day])
    const [stylesArrayContainer, setStylesArrayContainer] = useState([styles.container])
    const [stylesArrayBackground, setStylesArrayBackground] = useState([])
    const [stylesArrayTaches, setStylesArrayTaches] = useState([])
    const [stylesArrayFilter, setStylesArrayFilter] = useState([])

    const reset = () => {
        setStylesArrayBackground([])
        setStylesArrayContainer([styles.container])
        setStylesArrayText([styles.day])
        setStylesArrayTaches([])
        setStylesArrayFilter([])
    }

    useEffect(() => {
        reset()
        if(today) setStylesArrayText([...stylesArrayText, styles.isToday])
        if(past) setStylesArrayText([...stylesArrayText, styles.isPast])
        if(shadowed) setStylesArrayFilter([...stylesArrayFilter, styles.shadowed])
        if(occupied || (checkin && checkout)) setStylesArrayBackground([...stylesArrayBackground, styles.isOccupied])
        if(checkin && !checkout) setStylesArrayBackground([...stylesArrayBackground, styles.isCheckIn])
        if(checkout && !checkin) setStylesArrayBackground([...stylesArrayBackground, styles.isCheckOut])
        
        let color = ""
        for (let t = 0; t < taches.length; t++) {
            if (taches[t].case === "red") color ="red"
            else if(taches[t].case === "orange" && color !== "red") color = "orange"
            else if(taches[t].case === "yellow" && color !== "orange" && color !== "red") color = "yellow"
            else if(taches[t].case === "grey" && color !== "red" && color !== "yellow" && color !== "orange") color = "grey"
            else if(taches[t].case === "green" && color !== "red" && color !== "yellow" && color !== "orange" && color !== "grey") color = "green"
        }
        if(color !== "") setStylesArrayTaches([...stylesArrayTaches, 
                        color === "red" ? styles.isRed 
                        : color === "orange" ? styles.isOrange
                        : color === "yellow" ? styles.isJaune
                        : color === "green" ? styles.isGreen
                        : color === "grey" ? styles.isGrey 
                        : styles.isBlack])
        else if (selected) setStylesArrayTaches([...stylesArrayTaches, styles.isBlack])
    }, [shadowed, past, today, day, date, checkin, occupied, checkout, taches, selected])


    

    return (isSelectable ? 
        <Pressable style={stylesArrayContainer} onPress={onSelection}>
            <View style={stylesArrayBackground}></View>
            <View style={stylesArrayTaches}></View>
            <Text   style={stylesArrayText}>{day}</Text>
            <View style={stylesArrayFilter}></View>
        </Pressable>:<View style={stylesArrayContainer} onPress={onSelection}>
            <View style={stylesArrayBackground}></View>
            <View style={stylesArrayTaches}></View>
            <Text   style={stylesArrayText}>{day}</Text>
        </View>
    );

}