import Accueil from './pages/Accueil';
import Loading from './pages/Loading';
import { Route, Routes } from "react-router-native";
import { BrowserRouter } from "react-router-dom"
import Authentification from './pages/Authentification';
import Admin from './pages/Admin';
import Logement from './pages/Logement';
import { useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useState } from 'react';
import Recapitulatif from './pages/Recapitulatif';
import 'moment/locale/fr';
import {  StyleSheet, View } from 'react-native';
import { css_bg_black } from './constants/Colors';

export default function App() {
  const [loading, setLoading] = useState(false);

  const clearAsyncStorage = async () => {
    AsyncStorage.removeItem('view')
    AsyncStorage.removeItem('echeance')
    AsyncStorage.removeItem('workflow')
  }
  
  useEffect(() => {
    setLoading(true)
    clearAsyncStorage().then((r) => {
      setLoading(false)
    })
  }, [])

  return (loading ? <></> :
    <>
      <View style={styles.greyBackground}></View>
      <View style={styles.align}>
        <BrowserRouter>
          <Routes>
            <Route exact path="" element={<Authentification />} />
            <Route exact path="loading" element={<Loading />} />
            <Route exact path="accueil" element={<Accueil />} />
            <Route exact path="admin" element={<Admin />} />
            <Route exact path="logement" element={<Logement />} />
            <Route exact path="recap" element={<Recapitulatif />} />
          </Routes>
        </BrowserRouter>
      </View>
    </>
    
  );

}
const styles = StyleSheet.create({
  align: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  greyBackground: {
    backgroundColor: css_bg_black,
    position:"fixed",
    width: "100vw",
    height: "100vh"
  }
});