import { Pressable, StyleSheet, Text, View } from "react-native";
import { css_black, css_calendar_font_grey, css_darkBlue, css_grey } from "../../constants/Colors";
import { useState } from "react";
import moment from "moment-timezone";
import { useEffect } from "react";
import JourDuCalendrier from "./JourDuCalendrier";
import { Feather } from '@expo/vector-icons';

/**
 * Composant Calendrier permettant d'afficher l'élément Calendrier.
 * @param {*} param0 
 * @returns 
 */
export default function CalendrierPicker({selectedBegin = moment.tz("Europe/Paris"), selectedEnd = moment.tz("Europe/Paris"), setSelectedBegin, setSelectedEnd, enableOnlyEndSelection = false}) {
    const [weeks, setWeeks] = useState([]);
    const [fdOMonth, setFdOMonth] = useState(moment.tz("Europe/Paris"));
    const [countSelection, setCountSelection] = useState(enableOnlyEndSelection ? 1 : 0);
    const [currentMonth, setCurrentMonth] = useState(moment(selectedBegin).tz("Europe/Paris"));

    const configureCalendar = () => {
        const final = [];
        //On identifie le mois de la date de début sélectionnée
        const mois = moment(currentMonth).format("MM");
        const annee = moment(currentMonth).format("YYYY");

        //On ajoute tous les jours du mois
        //On cherche le premier jour à afficher
        const firstDayOfMonth = moment("01"+mois+annee, "DDMMYYYY");
        setFdOMonth(firstDayOfMonth);
        const rearrangedDay = firstDayOfMonth.day() === 0 ? 7 : firstDayOfMonth.day();
        final.push([]);
        //jours du mois précédent
        for (let i = rearrangedDay-1; i > 0; i--) {
            const currentDate = moment("01"+mois+annee, "DDMMYYYY").subtract(i, 'day');
            final[0].push({
                day: currentDate.format("D"),
                date: currentDate,
                today: moment.tz("Europe/Paris").isSame(currentDate, "day"),
                past: annee+mois > currentDate.format("YYYYMM"),
                checkin: moment(selectedBegin).tz("Europe/Paris").isSame(currentDate, "day"),
                checkout: moment(selectedEnd).tz("Europe/Paris").isSame(currentDate, "day"),
                occupied: moment(selectedBegin).tz("Europe/Paris").isBefore(currentDate, "day") && moment(selectedEnd).tz("Europe/Paris").isAfter(currentDate, "day"),
                taches: [],
                
            });
        }

        // On ajoute tous les jours du mois
        const indexDay = moment("01"+mois+annee, "DDMMYYYY");
        let indexWeek = final.length-1;
        let rearranged = indexDay.day() === 0 ? 7 : indexDay.day();
        while(indexDay.format("MMYYYY") === mois+annee) {
            rearranged = indexDay.day() === 0 ? 7 : indexDay.day();
            if (rearranged === 1 ) {
                indexWeek+=1;
                final.push([]);
            }
        
            final[indexWeek].push({
                day: indexDay.format("D"),
                date: indexDay.clone(),
                today: moment.tz("Europe/Paris").isSame(indexDay, "day"),
                past: annee+mois > indexDay.format("YYYYMMDD"),
                checkin: moment(selectedBegin).tz("Europe/Paris").isSame(indexDay, "day"),
                checkout: moment(selectedEnd).tz("Europe/Paris").isSame(indexDay, "day"),
                occupied: moment(selectedBegin).tz("Europe/Paris").isBefore(indexDay, "day") && moment(selectedEnd).tz("Europe/Paris").isAfter(indexDay, "day"),
                taches: [],
                
            });
            indexDay.add(1, 'day')
        }

        // On ajoute les jours du mois suivant
        rearranged = indexDay.day() === 0 ? 7 : indexDay.day();
        while(rearranged !== 1) {
            final[indexWeek].push({
                day: indexDay.format("D"),
                date: indexDay.clone(),
                today: moment.tz("Europe/Paris").isSame(indexDay, "day"),
                past: annee+mois > indexDay.format("YYYYMMDD"),
                checkin: moment(selectedBegin).tz("Europe/Paris").isSame(indexDay, "day"),
                checkout: moment(selectedEnd).tz("Europe/Paris").isSame(indexDay, "day"),
                occupied: moment(selectedBegin).tz("Europe/Paris").isBefore(indexDay, "day") && moment(selectedEnd).tz("Europe/Paris").isAfter(indexDay, "day"),
                taches: [],
                
            });
            indexDay.add(1, 'day')
            rearranged = indexDay.day() === 0 ? 7 : indexDay.day();
        }

        setWeeks(final);
    }

    useEffect(() => {
        setCurrentMonth(moment(selectedBegin).tz("Europe/Paris"));
        configureCalendar();
        
    }, [selectedBegin]);

    const onSelection = (date) => {
        if (countSelection === 0) {
            setSelectedBegin(date);
        }
        if (countSelection === 1) {
            setSelectedEnd(date);
        }
        setCountSelection(countSelection === 1 ? 0 : 1);
    }

    const executeNextMonth = () => {
        setCurrentMonth(currentMonth.add(1, "month"))
        configureCalendar()
    }

    const executePreviousMonth = () => {
        setCurrentMonth(currentMonth.subtract(1, "month"))
        configureCalendar()
    }
    return (
        <View style={styles.container}>
            <View style={styles.row}>
                    <Text style={styles.chevrons}>
                        <Pressable  onPress={(e) => {e.preventDefault();executePreviousMonth()}} >
                            <Feather name="chevrons-left" style={styles.monthFilterText} />
                        </Pressable>
                    </Text>
                    <Text style={styles.monthInitiale}>{`${moment(currentMonth).format("MMMM")[0].toUpperCase()}${moment(currentMonth).format("MMMM YYYY").substring(1)}`}</Text>
                    <Text style={styles.chevrons}>
                        <Pressable  onPress={(e) => {e.preventDefault();executeNextMonth()}} >
                            <Feather name="chevrons-right" style={styles.monthFilterText} />
                        </Pressable>
                    </Text>
                </View>
            <View style={styles.row}>
                <Text style={styles.dateInitiale}>L</Text>
                <Text style={styles.dateInitiale}>M</Text>
                <Text style={styles.dateInitiale}>M</Text>
                <Text style={styles.dateInitiale}>J</Text>
                <Text style={styles.dateInitiale}>V</Text>
                <Text style={styles.dateInitiale}>S</Text>
                <Text style={styles.dateInitiale}>D</Text>
            </View>
            {weeks.map((week, key) => {
                return (
                    <View   style={styles.row}
                            key={`CalendrierSemaine${key}`}>
                        {week.map((d, key) => {
                            return (
                                <JourDuCalendrier 
                                    key={`${key}WeekOfCalendar${d.day}`} 
                                    day={d.day}
                                    date={d.date}
                                    today={d.today}
                                    past={false}
                                    occupied={d.occupied}
                                    checkin={d.checkin}
                                    checkout={d.checkout}
                                    taches={d.taches}
                                    isSelectable={(countSelection === 1 && d.date.isSameOrAfter(moment(selectedBegin).tz("Europe/Paris"), "day")) || countSelection === 0 ? true : false}
                                    shadowed={!d.date.isSame(fdOMonth, "month")}
                                    selected={false}
                                    isPicker={true}
                                    onSelection={() => {onSelection(d.date)}} />
                                    
                            )
                        })  }
                    </View>
                        );
                    })
                    
            }

        </View>
    );

}
const styles = StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "column",
        width: "90%",
        shadowColor: css_black,
        shadowOffset: {width: 0, height: 0},
        shadowOpacity: 0.3,
        shadowRadius: 5,
        borderRadius: "15px",
        marginHorizontal: "5%"
    },
    row: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        marginVertical: "1vh",
        alignItems: "center"
    },
    dateInitiale: {
        width: "calc(100% / 7)",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        cursor: "default",
        color: css_calendar_font_grey
    },
    monthInitiale: {
        width: "calc(100% * 3 / 7)",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        cursor: "default",
        fontWeight: 700,
        color: css_darkBlue
    },
    chevrons: {
        width: "calc(100% * 2/ 7)",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        cursor: "default",
        fontWeight: 700,
        color: css_darkBlue
    },
});



