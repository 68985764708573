import { StyleSheet, View, Text, Pressable } from 'react-native';
import { css_black, css_darkBlue, css_red, css_white, css_white_modale } from '../../constants/Colors';
import Button from '../Button';
import { CheckBox } from 'react-native-elements';
import { screenWidth } from '../../constants/Const';

export default function UsersRecapModale({disappear = null, listOfAllUsers = [], selectedUsers = [], setSelectedUsers = null}) {

  const change = (id_utilisateur) => {
    if (selectedUsers.includes(id_utilisateur)) {
      setSelectedUsers(prev => [...prev.filter((u) => u !== id_utilisateur)]);
    } else {
      setSelectedUsers(prev => [...prev, id_utilisateur]);
    }
  }

  const clear = () => {
    const clearing = [];
    for (let i = 0; i < listOfAllUsers.length; i++) {
      clearing.push(listOfAllUsers[i].id_utilisateur);
    }
    setSelectedUsers(clearing);
  }

  const styles = StyleSheet.create({ 
    bg: {
      position: "fixed",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      width: Math.min(screenWidth, 500),
      height: "100vh",
      zIndex: 1,
      top: 0,
      left:screenWidth<500 ? 0 : (screenWidth-500)/2,
      bottom: 0,
      backgroundColor: css_white_modale
  },
  modale: {
      width: Math.min(0.9*screenWidth, 0.9*500),
      backgroundColor: css_white,
      borderRadius: "15px",
      borderColor: css_black,
      borderWidth: "3px",
      paddingVertical: "4vh",
      display: "flex",
      justifyContent: "space-around",
      alignContent: "center",
      alignItems: "center",
      cursor: "default"
  },
    reset: {
      marginVertical: "2vh",
      textDecorationStyle: "solid",
      textDecorationLine: "underline"
    },
    buttons: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      width: "80%"
    }
  });
  

  return (
      <Pressable    style={styles.bg}
                    onPress={disappear}>
        <Pressable  style={styles.modale} 
                    onPress={() => {}}>
            <Text>Liste des utilisateurs disponibles</Text>

            <View style={styles.buttons}>
              {listOfAllUsers.map((u) => {
                return (
                  <CheckBox title={u.utilisateur}
                            key={`UsersRecapModale${u.id_utilisateur}`}
                            checkedColor={css_darkBlue}
                            checked={selectedUsers.includes(u.id_utilisateur)}
                            onPress={(e) => {e.preventDefault(); change(u.id_utilisateur);}}
                  />);
              })}
                          
                {listOfAllUsers.length !== selectedUsers.length ? 
                  <Pressable  style={styles.reset}
                            onPress={() => {clear()}}>Réinitialiser les choix</Pressable> : <></>}

                <Button onPress={disappear} 
                        title={"Fermer"}
                        color={css_black}
                        borderColor={css_black}
                        size="medium"/>
                
            </View>
        </Pressable>


      </Pressable>
  );
}


