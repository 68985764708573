
export const saveToken = (token) => {
    setItem("token", token);
}

export const removeToken = () => {
    removeItem("token");
}

function setItem(key, value) {
    localStorage.setItem(key, value);
}

function removeItem(key) {
    localStorage.removeItem(key);
}
export const getToken = () => {
    const token = getStorageValue("token");
    return token;
}

function getStorageValue(key){
    const saved = localStorage.getItem(key);
    return saved || null;
  }
