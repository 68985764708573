import { useEffect, useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { css_black, css_grey } from '../constants/Colors';
import { MaterialIcons } from '@expo/vector-icons';

export default function Titre({texte, size, color, align = "center", icon=null, fontWeight=400, marginLeft=0, marginBottom=0}) {
    const [t, setTexte] = useState("");
    const [fs, setFontSize] = useState(1);
    const [c, setColor] = useState(css_black);
    const [fw, setFontWeight] = useState(400);
    const [ml, setMarginLeft] = useState(0)
    const [mb, setMarginBottom] = useState(0)

    useEffect(() => {
        setTexte(texte)
    }, [texte]);

    useEffect(() => {
        setFontSize(size);
    }, [size])

    useEffect(() => {
        setColor(color)
    }, [color])

    useEffect(() => {
        setFontWeight(fontWeight)
    }, [fontWeight])

    useEffect(() => {
        setMarginLeft(marginLeft)
    }, [marginLeft])

    useEffect(() => {
        setMarginBottom(marginBottom)
    }, [marginBottom])


    const styles = StyleSheet.create({
        container: {
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignContent: "center",
            flexDirection: "row",
            alignItems: "center"
        },
        titre: {
            marginLeft: `${ml}%`,
            fontSize: `${fs}rem`,
            textAlign: align,
            color: c,
            fontWeight: `${fw}`,
            marginBottom: `${mb}vh`
        },
        icon: {
            fontSize: `${fs}rem`,
            height: `${fs}rem`,
            color: css_grey
        }
    });

    return (
        <View style={styles.container}>
            {icon !== null ?<MaterialIcons name="location-pin" style={styles.icon} />:<></>}
            <Text style={styles.titre}>{t}</Text>
        </View>
      );
}
    

    