import { StyleSheet, View, Pressable, Text } from 'react-native';
import { useNavigate } from 'react-router-dom';
import Titre from '../components/Titre';
import { css_darkBlue, css_white } from '../constants/Colors';
import { MaterialIcons } from '@expo/vector-icons';
import { useEffect, useState } from 'react';
import AdminLink from '../components/Elements/AdminLink';
import ManageUtilisateurs from '../components/Administrateur/ManageUtilisateurs';
import ManageLogements from '../components/Administrateur/ManageLogements';
import ManageWorkflow from '../components/Administrateur/ManageWorkflow';
import FormUtilisateur from '../components/Administrateur/FormUtilisateur';
import Accueil from './Accueil';
import FormLogement from '../components/Administrateur/FormLogement';
import role_service from '../services/role.service';
import ManageAdministrateurs from '../components/Administrateur/ManageAdministrateurs';
import { screenWidth } from '../constants/Const';


export default function Admin() {
    const [loading, setLoading] = useState(false);
    const [displayedScreen, setDisplayedScreen] = useState("home"); 
    const [selectedUser, setSelectedUser] = useState(undefined);
    const [selectedLogement, setSelectedLogement] = useState(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        role_service.estAdministrateur()
        .then((r) => {
            if (!r) navigate("/");
            else setLoading(false)
        })
    }, [])

    if (loading) {
        return (
            <Titre texte="Chargement en cours ... " />
        )
    } else {
        return (
            <View style={styles.bigContainer}>
                <View style={styles.navbar}>
                    <Pressable  style={styles.navbarLink}
                                onPress={(e) => {
                                    if (displayedScreen === "home") {e.preventDefault();navigate('/accueil')} 
                                    else if (displayedScreen === "utilisateurs-add") {setDisplayedScreen("utilisateurs")}
                                    else if (displayedScreen === "utilisateurs-logement") {setDisplayedScreen("utilisateurs")}
                                    else if (displayedScreen === "utilisateurs-update") {setDisplayedScreen("utilisateurs")}
                                    else if (displayedScreen === "logements-add") {setDisplayedScreen("logements")}
                                    else if (displayedScreen === "logements-attribution") {setDisplayedScreen("logements")}
                                    else if (displayedScreen === "logements-update") {setDisplayedScreen("logements")}
                                    else {setDisplayedScreen("home")}    
                                }}>
                        <MaterialIcons name="arrow-back" size={24} color={css_white} />
                        <Text   style={styles.titre_back}>{displayedScreen === "home" ? "Accueil" : "Administration" }</Text>
                    </Pressable>
                </View>

                <View   style={styles.titre_ctn}>
                    <Text   style={styles.titre}>{displayedScreen === "home" ? "Administration" 
                                                : displayedScreen === "utilisateurs" ? "Gestion des utilisateurs" 
                                                : displayedScreen === "logements" ? "Gestion des logements" 
                                                : displayedScreen === "workflows" ? "Gestion des workflows" 
                                                : displayedScreen === "utilisateurs-add" ? "Ajout d'un utilisateur"
                                                : displayedScreen === "utilisateurs-logement" ? "Vu par "+selectedUser.nom+" "+selectedUser.prenom
                                                : displayedScreen === "utilisateurs-update" ? "Mise à jour d'un utilisateur"
                                                : displayedScreen === "logements-add" ? "Ajout d'un logement"
                                                : displayedScreen === "logements-attribution" ? "Attributions du logement "+selectedLogement.libelle
                                                : displayedScreen === "logements-update" ? "Mise à jour d'un logement"
                                                : displayedScreen === "admins" ? "Gestion des administrateurs"
                                                : ""}</Text>
                </View>
               
               {displayedScreen === "home" ?
                <View   style={styles.links_ctn}>
                    <AdminLink  titre="Gestion des utilisateurs"
                                isPressable={true}
                                actionOne={() => {displayedScreen === "utilisateurs" ? "home" : setDisplayedScreen("utilisateurs")}}/>
                    <AdminLink  titre="Gestion des logements"
                                isPressable={true}
                                actionOne={() => {displayedScreen === "logements" ? "home" : setDisplayedScreen("logements")}}/>
                    <AdminLink  titre="Gestion des workflows"
                                isPressable={true}
                                actionOne={() => {displayedScreen === "workflows" ? "home" : setDisplayedScreen("workflows")}}/>
                    <AdminLink  titre="Gestion des administrateurs"
                                isPressable={true}
                                actionOne={() => {displayedScreen === "admins" ? "home" : setDisplayedScreen("admins")}}/>
                </View>:<></>}

                {displayedScreen === "utilisateurs" ?<ManageUtilisateurs    selectedUser={selectedUser}
                                                                            setSelectedUser={setSelectedUser}
                                                                            setAction={setDisplayedScreen}
                                                                            /> : <></>}
                {displayedScreen === "utilisateurs-add" ?<FormUtilisateur onBack={() => {setDisplayedScreen("utilisateurs")}}/> : <></>}
                {displayedScreen === "utilisateurs-logement" ?<Accueil    seen_by={selectedUser.id_utilisateur}/> : <></>}
                {displayedScreen === "utilisateurs-update" ?<FormUtilisateur    onBack={() => {setDisplayedScreen("utilisateurs")}}
                                                                                toUpdate_id_utilisateur={selectedUser.id_utilisateur}
                                                                                toUpdate_nom={selectedUser.nom}
                                                                                toUpdate_prenom={selectedUser.prenom}
                                                                                toUpdate_mail={selectedUser.mail}
                                                                                toUpdate_telephone={selectedUser.phone}
                                                                                actual_username={selectedUser.utilisateur}/> : <></>}
                {displayedScreen === "logements" ?<ManageLogements  selectedLogement={selectedLogement}
                                                                    setSelectedLogement={setSelectedLogement}
                                                                    setAction={setDisplayedScreen}
                                                                    /> : <></>}
                {displayedScreen === "logements-update" ?<FormLogement  toUpdate_libelle={selectedLogement.libelle}
                                                                        toUpdate_numero_adresse={selectedLogement.numero_adresse}
                                                                        toUpdate_type_voie_adresse={selectedLogement.type_voie_adresse}
                                                                        toUpdate_nom_voie_adresse={selectedLogement.nom_voie_adresse}
                                                                        toUpdate_complement_adresse={selectedLogement.complement_adresse}
                                                                        toUpdate_ville_adresse={selectedLogement.ville_adresse}
                                                                        toUpdate_code_postal_adresse={selectedLogement.code_postal_adresse}
                                                                        toUpdate_photo={selectedLogement.photo}
                                                                        toUpdate_url_airbnb={selectedLogement.url_airbnb}  
                                                                        toUpdate_id_logement={selectedLogement.id_logement} 
                                                                        onBack={() => {setDisplayedScreen("logements")}}/> : <></>}
                {displayedScreen === "logements-add" ?<FormLogement onBack={() => {setDisplayedScreen("logements")}}/> : <></>}
                {displayedScreen === "logements-attribution" ?<ManageLogements  view={"attributions"}
                                                                                selectedLogement={selectedLogement}
                                                                                setSelectedLogement={setSelectedLogement}
                                                                                setAction={setDisplayedScreen}/> : <></>} 
                {displayedScreen === "workflows" ?<ManageWorkflow /> : <></>} 
                {displayedScreen === "admins" ? <ManageAdministrateurs /> : <></>}
            </View>
            
        );
    }
    
}

const styles = StyleSheet.create({
    navbar: {
        width: Math.min(screenWidth, 500),
        height: "7.5vh",
        backgroundColor: css_darkBlue
    },
    titre_back: {
        color: css_white,
        fontSize:"1.3rem",
        marginLeft: "2vw"    
    },  
    navbarLink: {
        marginLeft: "2vw",
        marginTop: "2vh",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    bigContainer: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: css_white,
        minHeight: "100vh",
        width: Math.min(screenWidth, 500)
    },
    titre: {
        fontSize:"1.5rem"
    },
    titre_ctn: {
        width:"100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "row"
    },
    links_ctn: {
        marginTop: "3vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%"
    }
});
