import { get } from "../modules/ms-api.module";

const estAdministrateur = async function() {
    const brut = await get({
        need_token: true,
        uri: "/role/est_admin"
    });
    const data = await brut.json();
    return data.result === "L'utilisateur est administrateur.";
}

const estNestorOuProprietaire = async function (id_logement, seen_by = undefined) {
    const parameters = [{"id_logement": `${id_logement}`}];
    if (seen_by !== undefined) parameters.push({"seen_by": seen_by})
    const brut = await get({
        need_token: true,
        uri: "/role/est_nestor_ou_proprietaire_sur_logement",
        parameters: parameters
    });
    const data = await brut.json();
    return data.result === "L'utilisateur est nestor ou propriétaire.";
}
const role_service = {estNestorOuProprietaire, estAdministrateur};
export default role_service;