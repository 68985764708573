import { get, del, post, put } from "../modules/ms-api.module";

const getUtilisateurs = async function () {
    const brut = await get({
        need_token: true,
        uri: "/utilisateur/utilisateurs"
    });
    const data = await brut.json();
    if (brut.status === 200) return data.result;
    else return [];
}

const getIdByMail = async function (mail) {
    const brut = await get({
        need_token: true,
        uri: "/utilisateur/identifiant_utilisateur_depuis_mail",
        parameters: [{
            mail: `${mail}`
        }]
    })
    const data = await brut.json();
    if (brut.status === 200) return data;
    else return data;
}

const deleteUtilisateur = async function (id_utilisateur) {
    const brut = await del({
        need_token: true,
        uri: "/utilisateur/",
        parameters: [{
            id_utilisateur: `${id_utilisateur}`
        }]
    })
    const data = await brut.json();
    if (brut.status === 200) return data;
    else return data;
}

const createUtilisateur = async function (nom,prenom,mail,telephone) {
    const brut = await post({
        need_token: true,
        uri: "/utilisateur/",
        body: {
            nom: `${nom}`,
            prenom: `${prenom}`,
            mail: `${mail}`,
            phone: `${telephone}`
        },
    })
    const data = await brut.json();
    if (brut.status === 200) return data;
    else return data;
}

const updateUtilisateur = async function (nom,prenom,mail,telephone,id_utilisateur) {
    const brut = await put({
        need_token: true,
        uri: "/utilisateur/",
        body: {
            nom: `${nom}`,
            prenom: `${prenom}`,
            mail: `${mail}`,
            phone: `${telephone}`
        },
        parameters: [{
            id_utilisateur: `${id_utilisateur}`
        }]
    })
    const data = await brut.json();
    if (brut.status === 200) return data;
    else return data;
}

const resetPassword = async function (id_utilisateur) {
    const brut = await get({
        need_token:true,
        uri: "/utilisateur/reset_password",
        parameters: [{
            id_utilisateur: `${id_utilisateur}`
        }]
    })
    const data = await brut.json();
    if (brut.status === 200) return data;
    else return data;
}

const utilisateur_service = {resetPassword, getIdByMail, getUtilisateurs, deleteUtilisateur, createUtilisateur, updateUtilisateur};
export default utilisateur_service;