import { useEffect, useState } from 'react';
import { StyleSheet, View, Pressable, Text } from 'react-native';
import TacheChip from '../Elements/TacheChip'
import Titre from '../Titre';
import programmation_service from '../../services/programmation.service';

export default function AjoutTache({idLogement, onUpdate, loading = false}) {
    const [selectedTache, setSelectedTache] = useState(-1);
    const [t, setTaches] = useState([]);
    const [e, setExecutables] = useState([]);

    useEffect(() => {
        if (idLogement !== -1) {
            programmation_service.getTachesExecutablesFromProgrammation(idLogement).then((res)=> {
                setExecutables(res)
                const tmp= [];
                for (let i = 0; i < res.length; i++) {
                if(tmp.filter(t => t.id_workflow === res[i].id_workflow).length < 1)
                    tmp.push(res[i])
                }
                setTaches(tmp)
            })
        }
    }, [])

    useEffect(() => {
        const finalObject = {};
        e.forEach((tache) => {
            if (tache.id_workflow === selectedTache) {
                finalObject.id_logement = idLogement;
                finalObject.id_etape = tache.id_etape;
                finalObject.id_workflow = tache.id_workflow;
                finalObject.libelle_etape = tache.libelle_etape;
                finalObject.libelle_workflow =  tache.libelle_workflow;
                finalObject.destinataires = tache.destinataires;
            }
        })
        onUpdate(finalObject)
    }, [selectedTache]);

    const styles = StyleSheet.create({
        actions: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            marginVertical: "5vh"
        },
        container: {
            paddingHorizontal: "5vw",
            paddingTop: "0vh",
            paddingBottom: "5vh"
        }
    });
    

    return (
        <View style={styles.container}>
            <Titre texte="Quelle tâche faut-il ajouter ?" size={1.2} />
            <View style={styles.actions}>
                {t.length === 0 ?
                <Text>Chargement de la liste des workflows disponibles...</Text>
                :<></>}
                
                {t.map((action) => {
                    return (
                        <Pressable  onPress={() => setSelectedTache(action.id_workflow)}
                                    key={`AjoutTacheExecutable${action.id_workflow}`}>
                            
                            <TacheChip  libelle={action.libelle_workflow}
                                        color="grey"
                                        selected={selectedTache === action.id_workflow} />
                                   
                        </Pressable>
                    )
                })}
            </View>
            <Titre texte="A quelle date ?" size={1.2} />
            
            
        </View>
    );
}

