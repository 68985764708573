import { del, get, post, put } from "../modules/ms-api.module";

const getAttributions = async function() {
    const brut = await get({
        need_token: true,
        uri: "/attribution/attributions"
    });
    const data = await brut.json();
    if (brut.status === 200) return data.result;
    else return [];
}

const updateAttribution = async function(id_logement, id_utilisateur, id_workflow) {
    const brut = await put({
        need_token: true,
        body: {
            id_workflow: `${id_workflow}`,
            id_utilisateur: `${id_utilisateur}`,
            id_logement: `${id_logement}`
        },
        uri: "/attribution/"
    });
    const data = await brut.json();
    if (brut.status !== 200) return false;
    else return data.result === "L'attribution a été modifiée.";
}

const getPersonnel = async function (id_logement) {
    const brut = await get({
        need_token: true,
        uri: "/attribution/attributions_au_logement",
        parameters: [{"id_logement": `${id_logement}`}]
    });
    const data = await brut.json();
    if (brut.status === 200 && data.result !== "L'utilisateur ne possède pas les attributions nécessaires.") {
        return data.result;
    }
    else return [];
}

const createAttribution = async function (id_utilisateur, droits, id_logement) {
    const brut = await post({
        need_token: true,
        uri: "/attribution/ajouter_attribution",
        body: {
            droits: `${JSON.stringify(droits)}`,
            id_utilisateur: `${id_utilisateur}`,
            id_logement: `${id_logement}`
        }
    });
    if (brut.status === 200) return true;
    else return false;
}

const createProprietaire = async function (id_utilisateur, id_logement) {
    const brut = await post({
        need_token: true,
        body: {
            id_utilisateur: `${id_utilisateur}`,
            id_logement: `${id_logement}`
        },
        uri: "/attribution/ajouter_proprietaire"
    });
    if (brut.status === 200) return true;
    else return false;
}

const deleteProprietaire = async function (id_utilisateur, id_logement) {
    const brut = await del({
        need_token: true,
        body: {
            id_utilisateur: `${id_utilisateur}`,
            id_logement: `${id_logement}`
        },
        uri: "/attribution/supprimer_proprietaire"
    });
    if (brut.status === 200) return true;
    else return false;
}
const createNestor = async function (id_utilisateur, id_logement) {
    const brut = await post({
        need_token: true,
        body: {
            id_utilisateur: `${id_utilisateur}`,
            id_logement: `${id_logement}`
        },
        uri: "/attribution/ajouter_nestor"
    });
    if (brut.status === 200) return true;
    else return false;
}

const deleteNestor = async function (id_utilisateur, id_logement) {
    const brut = await del({
        need_token: true,
        body: {
            id_utilisateur: `${id_utilisateur}`,
            id_logement: `${id_logement}`
        },
        uri: "/attribution/supprimer_nestor"
    });
    if (brut.status === 200) return true;
    else return false;
}

const getAdministrateurs = async function () {
    const brut = await get({
        need_token: true,
        uri: "/role/liste_administrateurs"
    });
    
    const data = await brut.json();
    if (brut.status === 200) return data.result;
    return [];
}

const destituer = async function (id_utilisateur) {
    const brut = await del({
        need_token: true,
        uri: "/role/destituer",
        parameters: [{"id_utilisateur": `${id_utilisateur}`}]
    });
    
    const data = await brut.json();
    if (brut.status === 200) return data.result;
    return [];
}

const add = async function (id_utilisateur) {
    const brut = await post({
        need_token: true,
        uri: "/role/ajout_administrateur",
        parameters: [{"id_utilisateur": `${id_utilisateur}`}]
    });
    
    const data = await brut.json();
    if (brut.status === 200) return data.result;
    return [];
}

const attribution_service = {deleteProprietaire,createProprietaire,add,destituer,getAdministrateurs, deleteNestor, createNestor, getAttributions, updateAttribution, getPersonnel, createAttribution};
export default attribution_service;